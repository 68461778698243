import React, {Component} from 'react';
import Container from 'react-bootstrap/Container';
import {withTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import PropTypes from 'prop-types';
import Header from 'components/Header';
import Footer from 'components/Footer';
import FullScreenLoader from 'components/Loader/FullScreenLoader';
import ToastContent from 'components/ToastContent';
import {logError} from 'services/error-handling/ErrorHandler';
import LargeHeader from 'components/LargeHeader';
import './AccountOrders.scss';
import OrderItem from 'components/AccountOrderOverview/OrderItem';
import DocumentTitle from 'components/DocumentTitle';
import {handleLegacyInternalApiCall, METHOD_GET} from 'services/api-requests/ApiCallHandler';

class AccountOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    this.getOrders();
  }

  getOrders = () => {
    const { t } = this.props;
    handleLegacyInternalApiCall('me/orders', METHOD_GET, true, null, (response) => {
      this.setState({
        orders: response.data,
        isLoading: false,
      });
    }, (error) => {
      logError(error);
      toast.error(<ToastContent type="error" message={t('error.orders.get')} />);
      this.setState({ isLoading: false });
    });
  };

  render() {
    const { t, match } = this.props;
    const { isLoading, orders } = this.state;

    if (isLoading === true) return <FullScreenLoader route={match} />;

    const filteredOrders = orders.filter((order) => (order.order.status === 'paid' || order.order.status === 'completed' || order.order.status === 'shipping'));

    return (
      <div className="Account AccountOrders">
        <DocumentTitle title={`Soul Survivor | ${t('menu.orders')}`} />
        <Header route={match} />
        <LargeHeader title={t('menu.orders')}>
          <div className="LargeHeaderInformation">
            <LocalMallOutlinedIcon className="LargeHeaderInformationIcon" />
            {filteredOrders.length}
            {' '}
            {filteredOrders.length > 1 ? t('account.orders') : t('account.order')}
          </div>
        </LargeHeader>

        <div className="Main">

          <Container>
            <div className="Block">
              <Row className="OrderItemHeader">
                <Col md={3}>
                  {t('account.table.event')}
                </Col>
                <Col md={2}>
                  {t('account.table.order')}
                </Col>
                <Col md={3}>
                  {t('account.table.confirmedAt')}
                </Col>
                <Col md={1} className="OrderItemAmount">
                  {t('account.table.amount')}
                </Col>
                <Col md={3}>
                  &nbsp;
                </Col>
              </Row>

              {orders.map((order) => (
                <OrderItem
                  key={order.order.orderToken}
                  orderNumber={order.order.orderNumber}
                  orderToken={order.order.orderToken}
                  totalPrice={order.order.totalPriceIncludingVat}
                  status={order.order.status}
                  confirmedAt={order.order.confirmedAt}
                  eventName={order.event.name}
                  fromDate={order.event.startDateTime}
                  tillDate={order.event.endDateTime}
                />
              ))}
            </div>
          </Container>
        </div>

        <Footer />
      </div>
    );
  }
}

export default withTranslation()(AccountOrders);

AccountOrders.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string,
  }),
};