import React, {useRef} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DateRangeIcon from '@material-ui/icons/DateRange';
import LocationIcon from '@material-ui/icons/LocationOnOutlined';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Moment from 'react-moment';
import {useTranslation} from 'react-i18next';
import './EventHeader.scss';
import {Sticky} from 'react-sticky';
import {classNames} from '../../util';
import {useInView} from "framer-motion";

type EventHeaderProps = {
  name: string;
  description: string;
  fromDate: string;
  tillDate: string;
  venue: string;
  mapsUrl?: string;
  websiteUrl?: string;
  children?: React.ReactNode;
  small: boolean;
};

export const EventHeader: React.FC<EventHeaderProps> = ({
                                                    name, description, fromDate, tillDate, venue, mapsUrl,
                                                    websiteUrl, children, small,
                                                  }) => {
    // const { headerVisible } = this.state;
  const {t, i18n} = useTranslation();

    let eventHeaderSmallSize = 7;
    if (small) eventHeaderSmallSize = 12;

    const ref = useRef(null);
    const headerVisible = useInView(ref, {
      margin: '-80px 0px 0px 0px'
    });


    return (
      <div className="EventHeaderWrapper">
          <div className="EventHeader" ref={ref}>
            <Container>
              <Row>
                <Col className="EventInformation" lg={7}>
                  <h1 className="EventName">{name}</h1>
                  <div className="EventInformationBar">
                    <div className="EventInformation EventDate">
                      <DateRangeIcon className="EventInformationIcon" />
                      <Moment date={fromDate} locale={i18n.language} format="D MMM YYYY" />
                      {fromDate !== tillDate && (
                        <span>
&nbsp;
                          {t('event.date_till')}
                          {' '}
                          <Moment
                            date={tillDate}
                            locale={i18n.language}
                            format="D MMM YYYY"
                          />
                        </span>
                      )}
                    </div>
                    {mapsUrl ? (
                      <div className="EventInformation EventVenue EventVenueLink">
                        <a href={mapsUrl} target="_blank" rel="noopener noreferrer">
                          <LocationIcon className="EventInformationIcon" />
                          {venue}
                        </a>
                      </div>
                    ) : (
                      <div className="EventInformation EventVenue">
                        <LocationIcon className="EventInformationIcon" />
                        {venue}
                      </div>
                    )}
                    {websiteUrl && (
                      <div className="EventInformation EventWebsite">
                        <a href={websiteUrl} target="_blank" rel="noopener noreferrer">
                          <OpenInNewIcon className="EventInformationIcon" />
                          {t('event.more_information')}
                        </a>
                      </div>
                    )}
                  </div>
                  {!small && (
                    <div className="EventAdditionalInformation">
                      <div className="EventDescription">
                        {description}
                      </div>
                    </div>
                  )}
                </Col>

                <Col lg={5} className="EventCart">
                  {children
                  && (
                    <Sticky topOffset={-80} bottomOffset={140}>
                      {({
                        style,
                        isSticky,
                      }) => (
                        <div
                          style={style}
                          className={classNames('StickyCart', isSticky && 'Active')}
                        >
                          {children}
                        </div>
                      )}
                    </Sticky>
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        {!headerVisible && (
          <div className="EventHeader EventHeaderSmallTop">
            <Container>
              <Row>
                <Col className="EventInformation" lg={eventHeaderSmallSize}>
                  <Row>
                    <Col sm={5}>
                      <h1 className="EventName">{name}</h1>
                    </Col>
                    <Col className="EventInformationBar" sm={7}>
                      <div className="EventInformation EventDate">
                        <DateRangeIcon className="EventInformationIcon" />
                        {fromDate === tillDate
                          ? <Moment date={fromDate} locale={i18n.language} format="D MMM YYYY" />
                          : (
                            <span>
                              <Moment
                                date={fromDate}
                                locale={i18n.language}
                                format="D MMM"
                              />
                              {' '}
                              {t('event.date_till')}
                              {' '}
                              <Moment
                                date={tillDate}
                                locale={i18n.language}
                                format="D MMM YYYY"
                              />
                            </span>
                          )}
                      </div>
                      {mapsUrl ? (
                        <div className="EventInformation EventVenue EventVenueLink">
                          <a href={mapsUrl} target="_blank" rel="noopener noreferrer">
                            <LocationIcon className="EventInformationIcon" />
                            {venue}
                          </a>
                        </div>
                      ) : (
                        <div className="EventInformation EventVenue">
                          <LocationIcon className="EventInformationIcon" />
                          {venue}
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </div>
    );
  }

export default EventHeader;