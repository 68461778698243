import React from 'react';
import VerifiedIcon from '@material-ui/icons/VerifiedUser';
import LockIcon from '@material-ui/icons/Lock';
import Container from 'react-bootstrap/Container';
import {useTranslation} from 'react-i18next';
import {
    LogoAmericanExpress,
    LogoBancontact,
    LogoIdeal,
    LogoMasterCard,
    LogoMollie,
    LogoVisa,
} from 'components/Logo/index';
import './CheckoutFooter.scss';

export default () => {
  const { t } = useTranslation();

  return (
    <div className="CheckoutFooter">
      <Container>
        <div className="SafePayment">
          <div className="SafePaymentItem">
            <VerifiedIcon className="SafePaymentIcon" />
            {' '}
            {t('cart.safePayment')}
          </div>
          <div className="SafePaymentItem">
            <LockIcon className="SafePaymentIcon" />
            {' '}
            {t('cart.encrypted')}
          </div>
        </div>
        <div className="PrivacyNotice">
          {t('cart.privacyNotice')}
          {' '}
          <a href={t('termsAndPrivacy.privacyStatement.url')} target="_blank" rel="noopener noreferrer">
            {t('termsAndPrivacy.privacyStatement.lower')}
          </a>
          .
        </div>
        <div className="PaymentMethods">
          <div className="PaymentMethod PaymentMethodFooter">
            <LogoIdeal />
          </div>
          <div className="PaymentMethod PaymentMethodFooter">
            <LogoBancontact />
          </div>
          <div className="PaymentMethod PaymentMethodFooter">
            <LogoMasterCard />
          </div>
          <div className="PaymentMethod PaymentMethodFooter">
            <LogoVisa />
          </div>
          <div className="PaymentMethod PaymentMethodFooter">
            <LogoAmericanExpress />
          </div>
          <div className="PaymentMethodFooter PaymentMethodsText">
            {t('cart.paymentMethods.via')}
          </div>
          <div className="PaymentMethod PaymentMethodFooter PaymentMethodVendor">
            <LogoMollie />
          </div>
        </div>
      </Container>
    </div>
  );
};
