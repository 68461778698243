import {useQuery} from "@tanstack/react-query";
import {handleInternalApiCall, METHOD_GET} from "../../services/api-requests/ApiCallHandler";
import {Amount} from "../../types/Amount.type";
import {CartItem} from "../../types/CartItem.type";
import LocalStorageService from 'services/storage/LocalStorageService';
import {trackCartChange} from "../../services/tracking/TrackingHandler";

export type GetCartQueryParams = {
    eventToken: string;
}

export const QUERY_KEY = 'cart';

const fetchEventTickets = async (params: GetCartQueryParams): Promise<{
    products: CartItem[],
    totalPrice: Amount,
    totalDiscount: Amount,
    voucher: any[],
    employee: boolean
}> => {
    const response = await handleInternalApiCall(
        `shop/cart/${LocalStorageService.getCartUuid()}/event/${params.eventToken}`,
        METHOD_GET,
        false,
        null
    );

    trackCartChange(response.data.products, response.data.totalPrice);

    return {
        products: response.data.products,
        totalPrice: response.data.totalPrice,
        totalDiscount: response.data.totalDiscount,
        voucher: response.data.voucher,
        employee: response.data.employee ?? false
    };
};

export const useGetCart = (params: GetCartQueryParams) => {
    return useQuery<{
        products: CartItem[],
        totalPrice: Amount,
        totalDiscount: Amount,
        voucher: any[],
        employee: boolean
    }, Error>(useGetCartQuery(params));
};

export const useGetCartQuery = (params: GetCartQueryParams) => {
    return {
        queryKey: [QUERY_KEY + params.eventToken],
        queryFn: () => fetchEventTickets(params)
    }
};