import noAuthAxios, {Method} from 'axios';
import authAxios from './auth-axios';

export const METHOD_GET = 'get';
export const METHOD_POST = 'post';
export const METHOD_PUT = 'put';
export const METHOD_DELETE = 'delete';

export const handleApiCall = (
    path: string,
    method: Method,
    authenticationNeeded: boolean,
    data: any,
    config?: object,
) => {
    const host = process.env.REACT_APP_BACK_OFFICE_URL;
    const axios = authenticationNeeded ? authAxios : noAuthAxios;

    const axiosConfig = {
        ...config,
        method,
        params: undefined,
        data: undefined,
        url: path,
        baseURL: `${host}/api`,
    };

    if (method === METHOD_GET) {
        axiosConfig.params = data;
    } else if (method === METHOD_POST || method === METHOD_PUT) {
        axiosConfig.data = data;
    }

    return axios(axiosConfig);
};

export const handleInternalApiCall = (
    path: string,
    method: Method,
    authenticationNeeded: boolean,
    data: any,
    config?: object
) => {
    return handleApiCall(
        `/internal/${path}`,
        method,
        authenticationNeeded,
        data,
        config
    );
};

export const handleLegacyInternalApiCall = (
    path: string,
    method: Method,
    authenticationNeeded: boolean,
    data: any,
    onSuccess: (response: any) => void,
    onError: (response: any) => void,
    config?: object
) => {
    handleInternalApiCall(path, method, authenticationNeeded, data, config)
        .then((response) => {
            onSuccess(response);
        })
        .catch((error) => {
            onError(error);
        });
};