import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import {useTranslation} from 'react-i18next';
import VoucherIcon from '@material-ui/icons/CardGiftcard';
import SubArrowIcon from '@material-ui/icons/SubdirectoryArrowRight';
import MoneyString from 'components/MoneyString/index';
import CartRemoveMessage from 'components/Cart/CartRemoveMessage';
import {Amount} from "../../types/Amount.type";
import {useChangeCartItem} from "../../hooks/Cart/useChangeCartItem";

type VoucherRowProps = {
    eventToken: string,
    code: string;
    name: string;
    amount: Amount;
}

const VoucherRow: React.FC<VoucherRowProps> = ({ eventToken, code, name, amount }) => {
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
    const {t, i18n} = useTranslation();

    const {removeItem} = useChangeCartItem({
        eventToken,
        errorMessage: t('error.cart.edit')
    });

  const handleRemove = () => {
    setShowRemoveConfirmation(true);
  };

  const handleRemoveCancel = () => {
    setShowRemoveConfirmation(false);
  };

  const handleRemoveConfirm = () => {
    setShowRemoveConfirmation(false);
    removeItem(code, true);
  };

  return (
      <div className="VoucherRow" key={`voucher-${code}`}>
        <SubArrowIcon />
        1x {' '}
        {name} {' '}
        à
        <span className="VoucherPrice">
        <MoneyString amount={amount.amount} currency={amount.currency} />
      </span>
        {' '}
        <span className="VoucherCode">
        (<VoucherIcon /> {code})
      </span>

        {showRemoveConfirmation && (
            <CartRemoveMessage
                cancel={handleRemoveCancel}
                confirm={handleRemoveConfirm}
                voucher
            />
        )}

        <Button
            variant="link"
            className="RemoveButton"
            onClick={handleRemove}
        >
          <DeleteIcon className="RemoveButtonIcon" />
        </Button>
      </div>
  );
};

export default VoucherRow;