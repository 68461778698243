/* eslint react/forbid-prop-types: 0 */
import React, {Component} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {withTranslation} from 'react-i18next';

import './Overview.scss';
import {LinkContainer} from 'react-router-bootstrap';
import moment from 'moment/moment';
import {generatePath, Redirect} from 'react-router-dom';
import {toast} from 'react-toastify';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';
import CheckoutFooter from 'components/CheckoutFooter';
import PaymentForm from 'components/Form/Payment/PaymentForm';
import SmallCart from 'components/Cart/SmallCart';
import Breadcrumbs from 'components/Breadcrumbs';
import DocumentTitle from 'components/DocumentTitle';
import ToastContent from 'components/ToastContent';
import YouthLeaderForm from 'components/Form/YouthLeaderForm';
import {logError} from 'services/error-handling/ErrorHandler';
import {handleLegacyInternalApiCall, METHOD_GET, METHOD_POST,} from 'services/api-requests/ApiCallHandler';
import LocalStorageService from 'services/storage/LocalStorageService';

class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: {
        firstName: '',
        lastName: '',
        email: '',
        street: '',
        houseNumber: '',
        postcode: '',
        city: '',
        country: '',
        birthdate: '',
        phoneNumber: '',
        businessName: '',
        preposition: '',
        houseNumberAddition: '',
        church: '',
      },
      hasItems: null,
      isLoading: true,
      showCheckOutInfo: false,
      employee: false,
      showPayment: true,
      amount: null,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    this.getAddressDetails();
  }

  toggleCheckOutInfo = () => {
    const { showCheckOutInfo } = this.state;
    this.setState({
      showCheckOutInfo: !showCheckOutInfo,
    });
  };

  togglePayment = (showPayment) => {
    this.setState({
      showPayment,
    });
  };

  getAddressDetails = () => {
    const { event, t } = this.props;
    handleLegacyInternalApiCall(`shop/cart/${LocalStorageService.getCartUuid()}/event/${
        event.token
      }/order-details/address`,
      METHOD_GET,
      false,
      null,
      (response) => {
        const { data } = response;

        const birthdate = moment(data.birthdate, 'YYYY-MM-DD');
        data.birthdate = birthdate.format('DD-MM-YYYY');

        this.setState({
          isLoading: false,
          details: data,
        });
      },
      (error) => {
        logError(error);
        toast.error(
          <ToastContent type="error" message={t('error.details.get')} />,
        );
        this.setState({ isLoading: false });
      },
    );
  };

  confirmOrder = (callback) => {
    const { event, t } = this.props;
    handleLegacyInternalApiCall(
      `shop/cart/${LocalStorageService.getCartUuid()}/event/${
        event.token
      }/confirm`,
      METHOD_POST,
      false,
      null,
      (response) => {
        callback(response.data.order_token, response.data.order_amount);
      },
      (error) => {
        logError(error);
        toast.error(
          <ToastContent type="error" message={t('error.order.post')} />,
        );
      },
    );
  };

  render() {
    const { t, event, i18n } = this.props;
    const {
      isLoading,
      details,
      employee,
      hasItems,
      showCheckOutInfo,
      showPayment,
      amount,
    } = this.state;

    if (isLoading === true) {
      return <Loader className="FormLoader" />;
    }

    if (details.length === 0) {
      return (
        <Redirect
          to={`/${i18n.language}${generatePath(t('routes:ticket.cart'), {
            eventSlug: event.slug,
          })}`}
        />
      );
    }

    return (
      <div className="Overview">
        <DocumentTitle
          title={`Soul Survivor ${event.name} | ${t(
            'order.event.overview.overview',
          )}`}
        />

        <Breadcrumbs
          active="overview"
          eventSlug={event.slug}
          employee={employee}
        />

        {hasItems === false ? (
          <div className="Block CartEmpty">
            <p>{t('cart.cart_is_empty')}</p>

            <LinkContainer to={`/${i18n.language}/${event.slug}`}>
              <Button variant="link">
                <ChevronLeftIcon />
                {' '}
                {t('button.back_to_tickets')}
              </Button>
            </LinkContainer>
          </div>
        ) : (
          <Row>
            <Col lg={7} className="CheckOutColumn">
              <div className="DetailsBlock Block">
                <h2>{t('order.event.overview.title')}</h2>
                <div className="BlockExplanation">
                  {t('order.event.overview.subtitle')}
                </div>
                <Row>
                  <Col md={6}>
                    <h4>{t('order.event.overview.nameAndAddress')}</h4>
                    {details.firstName}
                    {details.preposition && ` ${details.preposition}`}
                    {' '}
                    {details.lastName}
                    <br />
                    {details.street}
                    {' '}
                    {details.houseNumber}
                    {details.houseNumberAddition
                      && ` ${details.houseNumberAddition}`}
                    <br />
                    {details.postcode}
                    {' '}
                    {details.city}
                    <br />
                    {t(`countries.${details.country.toLowerCase()}`)}
                  </Col>
                  <Col md={6}>
                    <h4>{t('order.event.overview.contact')}</h4>
                    {details.email}
                    <br />
                    {details.phoneNumber}
                    <br />
                    {t('form.label.birthdate.short')}
                    :
                    {details.birthdate}
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <LinkContainer
                      to={`/${i18n.language}${generatePath(
                        t('routes:ticket.checkout'),
                        { eventSlug: event.slug },
                      )}`}
                    >
                      <Button className="BackLink" variant="link">
                        <ChevronLeftIcon />
                        {' '}
                        {t('button.edit_details')}
                      </Button>
                    </LinkContainer>
                  </Col>
                </Row>

                <hr className="DetailsDivider" />

                <SmallCart
                  eventSlug={event.slug}
                  eventToken={event.token}
                  track={false}
                />

                {event.acceptingYouthLeader && (
                  <div className="BlockYouthLeader">
                    <hr className="DetailsDivider" />

                    <h2>{t('order.event.overview.youthleader.title')}</h2>

                    <div className="BlockExplanation">
                      {t('order.event.overview.youthleader.subtitle')}
                    </div>

                    <YouthLeaderForm
                      onUpdate={this.togglePayment}
                      eventToken={event.token}
                      confirmOrder={this.confirmOrder}
                    >
                      <LinkContainer
                        to={`/${i18n.language}${generatePath(
                          t('routes:ticket.checkout'),
                          { eventSlug: event.slug },
                        )}`}
                      >
                        <Button
                          className="BackLink BackLinkBottom"
                          variant="link"
                        >
                          <ChevronLeftIcon />
                          {' '}
                          {t('button.back_to_details')}
                        </Button>
                      </LinkContainer>
                    </YouthLeaderForm>
                  </div>
                )}

                {showPayment && (
                  <div className="BlockPayment">
                    <hr className="DetailsDivider" />

                    <h2>{t('order.event.overview.payment.title')}</h2>

                    <div className="BlockExplanation">
                      {t('order.event.overview.payment.subtitle')}
                    </div>

                    <PaymentForm
                      country={details.country}
                      eventToken={event.token}
                      type="first"
                      confirmOrder={this.confirmOrder}
                      amount={amount}
                    >
                      <LinkContainer
                        to={`/${i18n.language}${generatePath(
                          t('routes:ticket.checkout'),
                          { eventSlug: event.slug },
                        )}`}
                      >
                        <Button
                          className="BackLink BackLinkBottom"
                          variant="link"
                        >
                          <ChevronLeftIcon />
                          {' '}
                          {t('button.back_to_details')}
                        </Button>
                      </LinkContainer>
                    </PaymentForm>
                  </div>
                )}
              </div>
            </Col>
            <Col lg={5} className="CheckOutColumn CheckOutInfo">
              <p>{t('order.event.overview.sidebar.laststep')}</p>

              <Button variant="link" onClick={this.toggleCheckOutInfo}>
                {showCheckOutInfo
                  ? t('order.event.overview.sidebar.readless')
                  : t('order.event.overview.sidebar.readmore')}
                {showCheckOutInfo ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Button>

              {showCheckOutInfo && (
                <div className="CheckOutInfoExpanded">
                  <hr />
                  <p>{t('order.event.overview.sidebar.pay')}</p>
                  <p>
                    <strong>
                      {t('order.event.overview.sidebar.personalizeheader')}
                    </strong>
                    <br />
                    {t('order.event.overview.sidebar.personalize')}
                  </p>
                  <p>
                    <strong>
                      {t('order.event.overview.sidebar.errorheader')}
                    </strong>
                    <br />
                    {t('order.event.overview.sidebar.error')}
                    {' '}
                    <a href="mailto:tickets@soulsurvivor.nl">
                      tickets@soulsurvivor.nl
                    </a>
                    {' '}
                    {t('order.event.overview.sidebar.error_2')}
                  </p>
                  <p>
                    <strong>
                      {t('order.event.overview.sidebar.detailsheader')}
                    </strong>
                    <br />
                    {t('order.event.overview.sidebar.details')}
                  </p>

                  <Button
                    href={t('termsAndPrivacy.privacyStatement.url')}
                    variant="link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('order.event.overview.sidebar.privacy')}
                    {' '}
                    <ChevronRightIcon />
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        )}

        <CheckoutFooter />
      </div>
    );
  }
}

export default withTranslation()(Overview);

Overview.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  event: PropTypes.shape({
    name: PropTypes.string,
    token: PropTypes.string,
    slug: PropTypes.string,
    acceptingYouthLeader: PropTypes.bool,
    description: PropTypes.string,
    date: PropTypes.string,
    fromDate: PropTypes.string,
    tillDate: PropTypes.string,
    location: PropTypes.string,
    tickets: PropTypes.any,
    websiteUrl: PropTypes.string,
    mapsUrl: PropTypes.string,
  }).isRequired,
};
