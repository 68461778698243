import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import PropTypes from 'prop-types';
import FullScreenLoader from 'components/Loader/FullScreenLoader';
import {generatePath, Redirect} from 'react-router-dom';
import ToastContent from 'components/ToastContent';
import {logError} from 'services/error-handling/ErrorHandler';
import {handleLegacyInternalApiCall, METHOD_GET,} from 'services/api-requests/ApiCallHandler';
import LocalStorageService from 'services/storage/LocalStorageService';

class OrderStart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: null,
      isLoading: true,
    };
  }

  componentDidMount() {
    const { t, match } = this.props;

    handleLegacyInternalApiCall(
      `shop/order/${match.params.orderSlug}`,
      METHOD_GET,
      false,
      null,
      (response) => {
        if (match.params.cartUuid === response.data.order.cartUuid) {
          LocalStorageService.setCartUuid(response.data.order.cartUuid);

          this.setState({
            event: response.data.event,
            isLoading: false,
          });
        } else {
          toast.error(
            <ToastContent type="error" message={t('error.order.get')} />,
          );
        }
      },
      (error) => {
        logError(error);
        toast.error(
          <ToastContent type="error" message={t('error.order.get')} />,
        );
        this.setState({ isLoading: false });
      },
    );
  }

  render() {
    const { t, i18n, match } = this.props;
    const { event, isLoading } = this.state;

    if (isLoading) {
      return <FullScreenLoader route={match} />;
    }

    return (
      <Redirect
        to={`/${i18n.language}${generatePath(t('routes:ticket.checkout'), {
          eventSlug: event.slug,
        })}`}
      />
    );
  }
}

export default withTranslation()(OrderStart);

OrderStart.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      orderSlug: PropTypes.string,
      cartUuid: PropTypes.string,
    }).isRequired,
  }).isRequired,
};
