import {Component} from 'react';
import PropTypes from 'prop-types';

class DocumentTitle extends Component {
  componentDidMount = () => {
    const { title } = this.props;
    document.title = title;
  };

  componentDidUpdate = () => {
    const { title } = this.props;
    document.title = title;
  };

  render() {
    return null;
  }
}

export default DocumentTitle;

DocumentTitle.propTypes = {
  title: PropTypes.string.isRequired,
};
