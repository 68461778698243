import React, {Component} from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {ValidatorForm} from 'react-form-validator-core';
import {withTranslation} from 'react-i18next';
import './Form.scss';
import {generatePath, Redirect} from 'react-router-dom';
import moment from 'moment';
import {Alert} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {LinkContainer} from 'react-router-bootstrap';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CreateIcon from '@material-ui/icons/Create';
import Spinner from 'react-bootstrap/Spinner';
import PropTypes from 'prop-types';
import Checkbox from 'components/Input/Checkbox';
import CheckboxGroup from 'components/Input/CheckboxGroup';
import {logError} from 'services/error-handling/ErrorHandler';
import ToastContent from 'components/ToastContent';
import Dropdown from 'components/Input/Dropdown';
import Loader from 'components/Loader';
import RadioGroup from 'components/RadioGroup/index';
import FormItem from 'components/Form/FormItem';
import Input from 'components/Input/index';
import {
  handleLegacyInternalApiCall,
  METHOD_DELETE,
  METHOD_GET,
  METHOD_POST,
  METHOD_PUT,
} from 'services/api-requests/ApiCallHandler';

class PersonalizeForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        firstName: '',
        preposition: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        dateOfBirth: '',
        hasDietOrAllergies: false,
        otherDietOrAllergy: '',
        note: '',
        selectedAttendee: { label: props.t('personalize.new'), value: '0' },
      },
      approveUseDetails: false,
      submitDisabled: true,
      dietOrAllergies: {
        vegetarian: false,
        vegan: false,
        glutenfree: false,
        lactose: false,
        nuts: false,
        other: false,
      },
      visitedFields: {},
      changedFieldsSinceFormSubmission: {},
      validation: {},
      isSaved: false,
      isLoading: false,
      showForm: true,
      generalError: '',
      isSubmitting: false,
    };

    window.onbeforeunload = function () {
      return true;
    };
  }

  componentDidMount() {
    const { ticket } = this.props;
    ValidatorForm.addValidationRule('isDate', (value) => this.isDate(value));

    ValidatorForm.addValidationRule('isSelected', (value) => {
      if (value === undefined) return false;
      if (value === null) return false;

      return (value.value !== '' && value.value !== null);
    });

    if (ticket.attendee !== null) {
      this.changeAttendeeInformation(ticket.attendee.token);
    }
  }

  isDate = (date) => {
    const regex = /^([0-2][0-9]|(3)[0-1])(-)(((0)[0-9])|((1)[0-2]))(-)\d{4}$/i;
    return regex.test(date);
  };

  handleAllergiesChange = (event) => {
    const { data, approveUseDetails } = this.state;
    const { dietOrAllergies } = this.state;
    const selectedDietOrAllergies = {
      ...dietOrAllergies,
      [event.target.name]: event.target.value,
    };

    this.setState({
      dietOrAllergies: selectedDietOrAllergies,
      submitDisabled: this.checkSubmitDisabledStatus(data.selectedAttendee,
        approveUseDetails,
        data.hasDietOrAllergies,
        selectedDietOrAllergies),
    });
  };

  handleChange = (event, callback = (() => {
  })) => {
    const {
      data, changedFieldsSinceFormSubmission, approveUseDetails, dietOrAllergies,
    } = this.state;
    const newData = {
      ...data,
      [event.target.name]: event.target.value,
    };
    this.setState({
      data: newData,
      changedFieldsSinceFormSubmission: {
        ...changedFieldsSinceFormSubmission,
        [event.target.name]: true,
      },
      validation: {
        [event.target.name]: false,
      },
      submitDisabled: this.checkSubmitDisabledStatus(data.selectedAttendee,
        approveUseDetails,
        newData.hasDietOrAllergies,
        dietOrAllergies),
    }, callback);
  };

  handleFieldVisit = (event) => {
    const { visitedFields } = this.state;
    this.setState({
      visitedFields: {
        ...visitedFields,
        [event.target.name]: true,
      },
    });
  };

  checkSubmitDisabledStatus = (attendeeToken, approveUseDetails,
    hasDietsOrAllergies, dietOrAllergies) => {
    const { showForm } = this.state;
    if (attendeeToken !== null && attendeeToken.value !== '0') {
      return showForm && !approveUseDetails;
    }

    const selectedDietOrAllergies = Object.keys(dietOrAllergies)
      .filter((key) => dietOrAllergies[key]);
    if (approveUseDetails && hasDietsOrAllergies && selectedDietOrAllergies.length > 0) {
      return false;
    }
    if (approveUseDetails && !hasDietsOrAllergies) {
      return false;
    }
    return true;
  };

  handleApproveUseDetailsChange = (event) => {
    const { data, dietOrAllergies } = this.state;
    this.setState({
      approveUseDetails: event.target.value,
      submitDisabled: this.checkSubmitDisabledStatus(data.selectedAttendee,
        event.target.value,
        data.hasDietOrAllergies,
        dietOrAllergies),
    });
  };

  handlePostError = (error) => {
    const { t } = this.props;
    if (error.response && error.response.status === 400) {
      let errorMessage = '';
      let validation = {};

      if (error.response.data.fields !== undefined) {
        validation = error.response.data.fields;
      }
      if (error.response.data.message !== undefined) {
        errorMessage = error.response.data.message;

        toast.error(<ToastContent type="error" message={errorMessage} />);
      }

      this.setState({
        validation,
      });
    } else {
      logError(error);
      toast.error(<ToastContent type="error" message={t('error.personalize.post')} />);
    }
    this.setState({
      submitDisabled: false,
      isSubmitting: false,
    });
  };

  assignVisitorToTicket = (visitorToken) => {
    const { ticket } = this.props;
    handleLegacyInternalApiCall(`ticket/${ticket.token}/assign`, METHOD_POST, true, {
      visitor_token: visitorToken,
    }, () => {
      this.setState({
        isSaved: true,
      });
    }, (error) => {
      this.handlePostError(error);
    });
  };

  createVisitor = () => {
    this.createOrUpdateVisitor(false);
  };

  updateVisitor = () => {
    this.createOrUpdateVisitor(true);
  };

  createOrUpdateVisitor = (update) => {
    const { data } = this.state;
    const { orderSlug, ticket } = this.props;
    const dateOfBirth = moment(data.dateOfBirth, 'DD-MM-YYYY');

    handleLegacyInternalApiCall(`order/${orderSlug}/visitor${update ? `/${data.selectedAttendee.value}` : ''}`,
      (update ? METHOD_PUT : METHOD_POST),
      true,
      {
        firstName: data.firstName,
        preposition: data.preposition,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber,
        email: data.email,
        dateOfBirth: dateOfBirth.format('YYYY-MM-DD'),
        note: data.note,
      },
      (response) => {
        if (ticket.hasDietPreference && (data.hasDietOrAllergies || update)) {
          this.addDietInformation(response.data.token, update);
        } else {
          this.assignVisitorToTicket(response.data.token);
        }
      },
      (error) => {
        this.handlePostError(error);
      });
  };

  addDietInformation = (attendeeToken, update) => {
    const { data } = this.state;
    const { ticket, eventToken, t } = this.props;
    let selectedDietOrAllergiesText = null;

    if (ticket.hasDietPreference && data.hasDietOrAllergies) {
      const { dietOrAllergies } = this.state;
      const selectedDietOrAllergies = Object.keys(dietOrAllergies)
        .filter((key) => dietOrAllergies[key]);
      selectedDietOrAllergiesText = selectedDietOrAllergies.map((dietOrAllergy) => {
        if (dietOrAllergy === 'other') {
          return `${t(`form.label.diets.short.${dietOrAllergy}`,
            { lng: 'nl' })}: ${data.otherDietOrAllergy}`;
        }
        return t(`form.label.diets.short.${dietOrAllergy}`, { lng: 'nl' });
      }).join('; ');

      handleLegacyInternalApiCall(`event/${eventToken}/attendee/${attendeeToken}/diet`,
        METHOD_PUT,
        true,
        {
          preference: selectedDietOrAllergiesText,
        },
        () => {
          this.assignVisitorToTicket(attendeeToken);
        },
        (error) => {
          this.handlePostError(error);
        });
    } else if (ticket.hasDietPreference && update) {
      handleLegacyInternalApiCall(`event/${eventToken}/attendee/${attendeeToken}/diet`,
        METHOD_DELETE,
        true,
        null,
        () => {
          this.assignVisitorToTicket(attendeeToken);
        },
        (error) => {
          this.handlePostError(error);
        });
    }
  };

  clearDietInformation = () => {
    const { data, dietOrAllergies } = this.state;

    const newData = {
      ...data,
      hasDietOrAllergies: false,
      otherDietOrAllergy: '',
    };

    const newDietOrAllergies = dietOrAllergies;

    Object.keys(dietOrAllergies).forEach((key) => {
      newDietOrAllergies[key] = false;
    });

    this.setState({
      data: newData,
      dietOrAllergies: newDietOrAllergies,
    });
  };

  getDietInformation = (attendeeToken) => {
    const { dietOrAllergies } = this.state;
    const { eventToken, t } = this.props;
    handleLegacyInternalApiCall(`event/${eventToken}/attendee/${attendeeToken}/diet`,
      METHOD_GET,
      false,
      null,
      (response) => {
        if (typeof response.data.preference !== 'undefined') {
          const { data } = this.state;
          const preference = response.data.preference.split('; ');

          const newDietOrAllergies = dietOrAllergies;
          let otherDietOrAllergy = '';

          Object.keys(newDietOrAllergies).forEach((key) => {
            newDietOrAllergies[key] = false;
          });

          const dietOrAllergyOptions = this.dietOrAllergyOptions();

          preference.forEach((dietOrAllergy) => {
            const dietOrAllergyLabel = dietOrAllergy.split(':')[0];

            const database = dietOrAllergyOptions.find(
              (selectedDietOrAllergy) => dietOrAllergyLabel === selectedDietOrAllergy.database,
            );
            newDietOrAllergies[database.name] = true;

            if (database.name === 'other') {
              const [, allergyText] = dietOrAllergy.split(':');
              otherDietOrAllergy = allergyText;
            }
          });

          const newData = {
            ...data,
            hasDietOrAllergies: true,
            otherDietOrAllergy,
          };

          this.setState({
            data: newData,
            dietOrAllergies: newDietOrAllergies,
          });
        } else {
          this.clearDietInformation();
        }
      },
      (error) => {
        logError(error);
        toast.error(<ToastContent type="error" message={t('error.diet.get')} />);
      });
  };

  handleSubmit = (event) => {
    const { data } = this.state;
    event.preventDefault();
    window.onbeforeunload = null;

    if (this.form.isFormValid()) {
      this.setState({
        submitDisabled: true,
        isSubmitting: true,
      });

      if (data.selectedAttendee !== null && data.selectedAttendee.value !== '0') {
        this.updateVisitor();
      } else {
        this.createVisitor();
      }
    }

    return false;
  };

  changeAttendeeInformation = (attendeeToken) => {
    const { attendees, ticket } = this.props;
    const { dietOrAllergies } = this.state;
    const attendee = attendees.find((singleAttendee) => singleAttendee.token === attendeeToken);
    const birthdate = moment(attendee.dateOfBirth, 'YYYY-MM-DD');

    const { data } = this.state;
    const selectedAttendee = {
      label: (attendee.preposition ? `${attendee.firstName} ${attendee.preposition} ${attendee.lastName}` : `${attendee.firstName} ${attendee.lastName}`),
      value: attendee.token,
    };

    if (typeof attendee.phoneNumber !== 'undefined') {
      attendee.phoneNumber = attendee.phoneNumber.replace(/ /g, '');
    }

    const newData = {
      ...data,
      firstName: attendee.firstName,
      preposition: attendee.preposition,
      lastName: attendee.lastName,
      phoneNumber: attendee.phoneNumber,
      email: attendee.email,
      dateOfBirth: birthdate.format('DD-MM-YYYY'),
      hasDietOrAllergies: false,
      otherDietOrAllergy: '',
      note: attendee.note,
      selectedAttendee,
    };

    this.setState({
      data: newData,
      approveUseDetails: true,
      submitDisabled: this.checkSubmitDisabledStatus(selectedAttendee,
        true,
        false,
        dietOrAllergies),
      showForm: false,
    });

    if (ticket.hasDietPreference) {
      this.getDietInformation(attendeeToken);
    }
  };

  handleAttendeeSelect = (attendeeToken) => {
    this.handleChange({ target: { name: 'selectedAttendee', value: attendeeToken } }, function () {
      if (attendeeToken.value === '0') {
        const { data } = this.state;
        const newData = {
          ...data,
          firstName: '',
          preposition: '',
          lastName: '',
          phoneNumber: '',
          email: '',
          dateOfBirth: '',
          hasDietOrAllergies: false,
          otherDietOrAllergy: '',
          note: '',
        };

        this.setState({
          data: newData,
          approveUseDetails: false,
          submitDisabled: true,
          showForm: true,
        });
      } else {
        this.changeAttendeeInformation(attendeeToken.value);
      }
    });
  };

  showForm = () => {
    this.setState({
      showForm: true,
    });
  };

  dietOrAllergyOptions = () => {
    const { t } = this.props;
    const { dietOrAllergies } = this.state;
    return [
      {
        value: dietOrAllergies.vegetarian,
        name: 'vegetarian',
        label: t('form.label.diets.long.vegetarian'),
        database: t('form.label.diets.short.vegetarian', { lng: 'nl' }),
        onChange: this.handleAllergiesChange,
      },
      {
        value: dietOrAllergies.vegan,
        name: 'vegan',
        label: t('form.label.diets.long.vegan'),
        database: t('form.label.diets.short.vegan', { lng: 'nl' }),
        onChange: this.handleAllergiesChange,
      },
      {
        value: dietOrAllergies.glutenfree,
        name: 'glutenfree',
        label: t('form.label.diets.long.glutenfree'),
        database: t('form.label.diets.short.glutenfree', { lng: 'nl' }),
        onChange: this.handleAllergiesChange,
      },
      {
        value: dietOrAllergies.lactose,
        name: 'lactose',
        label: t('form.label.diets.long.lactose'),
        database: t('form.label.diets.short.lactose', { lng: 'nl' }),
        onChange: this.handleAllergiesChange,
      },
      {
        value: dietOrAllergies.nuts,
        name: 'nuts',
        label: t('form.label.diets.long.nuts'),
        database: t('form.label.diets.short.nuts', { lng: 'nl' }),
        onChange: this.handleAllergiesChange,
      },
      {
        value: dietOrAllergies.other,
        name: 'other',
        label: t('form.label.diets.long.other'),
        database: t('form.label.diets.short.other', { lng: 'nl' }),
        onChange: this.handleAllergiesChange,
      },
    ];
  };

  render() {
    const {
      ticket,
      attendees,
      i18n,
      t,
      orderSlug,
    } = this.props;

    const {
      data, dietOrAllergies, generalError, isSaved, isLoading, showForm,
      validation, approveUseDetails, isSubmitting, submitDisabled,
    } = this.state;

    const attendeesOptions = attendees.map((attendee) => ({
      label: (attendee.preposition ? `${attendee.firstName} ${attendee.preposition} ${attendee.lastName}` : `${attendee.firstName} ${attendee.lastName}`),
      value: attendee.token,
    }));

    attendeesOptions.unshift({ label: t('personalize.new'), value: '0' });

    const required = t('form.validation.required');
    const invalid = t('form.validation.invalid');

    const age = this.isDate(data.dateOfBirth) ? moment()
      .diff(moment(data.dateOfBirth, 'DD-MM-YYYY'), 'years') : 18;

    if (isSaved === true) {
      return (
        <Redirect
          to={`/${i18n.language}${generatePath(t('routes:ticket.account_orders_order'),
            { orderSlug })}`}
        />
      );
    }

    if (isLoading) {
      return <Loader className="FormLoader" />;
    }

    if (generalError !== '') {
      return <Alert variant="danger">{generalError}</Alert>;
    }

    return (
      <ValidatorForm
        ref={(node) => { this.form = node; }}
        onSubmit={this.handleSubmit}
        id="PersonalizeTicketForm"
        method="post"
      >
        {attendeesOptions.length > 1 && (
          <div>
            <FormItem label={t('form.label.link_to')}>
              <div className="AttendeesDropdown">
                <Dropdown
                  options={attendeesOptions}
                  validators={['isSelected']}
                  errorMessages={[required]}
                  placeholder={t('form.label.link_to')}
                  onChange={this.handleAttendeeSelect}
                  onBlur={this.handleFieldVisit}
                  value={data.selectedAttendee}
                  name="selectedAttendee"
                />
                {!showForm
                && (
                  <div>
                    <Button className="EditDetailsButton" variant="link" onClick={this.showForm}>
                      <CreateIcon />
                      {' '}
                      {t('button.edit_details_of')}
                      {' '}
                      {data.firstName}
                    </Button>
                  </div>
                )}
              </div>
            </FormItem>
          </div>
        )}
        {showForm && (
          <div>
            <div className="FormExplanation">
              {t('form.explanation.required')}
            </div>
            <FormItem label={t('form.label.name')} required>
              <Col md={5}>
                <Input
                  placeholder={t('form.label.firstName')}
                  validators={['required', 'isString']}
                  errorMessages={[required, invalid]}
                  name="firstName"
                  onChange={this.handleChange}
                  onBlur={this.handleFieldVisit}
                  value={data.firstName}
                  serverError={validation.firstName}
                />
              </Col>
              <Col md={2}>
                <Input
                  placeholder={t('form.label.preposition.short')}
                  // validators={['isString']}
                  // errorMessages={[invalid]}
                  name="preposition"
                  onChange={this.handleChange}
                  onBlur={this.handleFieldVisit}
                  value={data.preposition}
                />
              </Col>
              <Col md={5}>
                <Input
                  placeholder={t('form.label.lastName')}
                  validators={['required', 'isString']}
                  errorMessages={[required, invalid]}
                  name="lastName"
                  onChange={this.handleChange}
                  onBlur={this.handleFieldVisit}
                  value={data.lastName}
                  serverError={validation.lastName}
                />
              </Col>
            </FormItem>
            <FormItem label={t('form.label.email')}>
              <Col md={12}>
                <Input
                  placeholder={t('form.label.email')}
                  validators={['isEmail']}
                  errorMessages={[invalid]}
                  name="email"
                  onChange={this.handleChange}
                  onBlur={this.handleFieldVisit}
                  value={data.email}
                  serverError={validation.email}
                />
              </Col>
            </FormItem>
            <FormItem label={t('form.label.phoneNumber')}>
              <Col md={12}>
                <Input
                  placeholder={t('form.label.phoneNumber')}
                  validators={['matchRegexp:^[0-9\\+]*$']}
                  errorMessages={[invalid]}
                  name="phoneNumber"
                  onChange={this.handleChange}
                  onBlur={this.handleFieldVisit}
                  value={data.phoneNumber}
                  serverError={validation.phoneNumber}
                />
              </Col>
            </FormItem>
            <FormItem label={t('form.label.birthdate.long')} required>
              <Col md={4}>
                <Input
                  placeholder="DD-MM-YYYY"
                  validators={['required', 'isDate']}
                  errorMessages={[required, invalid]}
                  name="dateOfBirth"
                  onChange={this.handleChange}
                  onBlur={this.handleFieldVisit}
                  value={data.dateOfBirth}
                  mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                  serverError={validation.dateOfBirth}
                />
              </Col>
            </FormItem>
          </div>
        )}

        {(showForm || ticket.hasDietPreference) && (
          <div>
            <hr />

            <h2>{t('personalize.notes.title')}</h2>
            <div className="BlockExplanation">{t('personalize.notes.subtitle')}</div>
          </div>
        )}

        {ticket.hasDietPreference && (
          <div>
            <FormItem label={t('form.label.hasDietOrAllergies')}>
              <Col md={12}>
                <RadioGroup
                  options={[
                    {
                      label: t('form.label.yes'),
                      value: true,
                    },
                    {
                      label: t('form.label.no'),
                      value: false,
                    },
                  ]}
                  onChange={this.handleChange}
                  onBlur={this.handleFieldVisit}
                  value={data.hasDietOrAllergies}
                  variant="horizontal"
                  name="hasDietOrAllergies"
                />
              </Col>
            </FormItem>
            {data.hasDietOrAllergies === true && (
              <div>
                <FormItem>
                  <CheckboxGroup
                    checkboxes={this.dietOrAllergyOptions()}
                    columns={2}
                    serverError={validation.dietOrAllergies}
                  />
                </FormItem>

                {dietOrAllergies.other === true
                && (
                  <FormItem label={t('form.label.otherDietOrAllergy')}>
                    <Col md={12}>
                      <Input
                        placeholder={t('form.label.otherDietOrAllergy')}
                        validators={['required']}
                        errorMessages={[required, invalid]}
                        name="otherDietOrAllergy"
                        onChange={this.handleChange}
                        onBlur={this.handleFieldVisit}
                        value={data.otherDietOrAllergy}
                        serverError={validation.otherDietOrAllergy}
                      />
                    </Col>
                  </FormItem>
                )}
              </div>
            )}
          </div>
        )}

        {showForm && (
          <div>
            <FormItem label={t('form.label.note')}>
              <Col md={12}>
                <Input
                  placeholder={t('form.label.note')}
                  name="note"
                  onChange={this.handleChange}
                  onBlur={this.handleFieldVisit}
                  value={data.note}
                  serverError={validation.note}
                  as="textarea"
                  rows="5"
                />
              </Col>
            </FormItem>

            <hr />

            <FormItem>
              <Col md={12}>
                <Checkbox
                  label={`${t('form.label.approveUseDetails') + (age < 18 ? t(
                    'form.label.approveUseDetailsParents',
                  ) : '')}.`}
                  name="approveUseDetails"
                  onChange={this.handleApproveUseDetailsChange}
                  value={approveUseDetails}
                />
              </Col>
            </FormItem>

          </div>
        )}

        <Row className="Buttons">
          <Col md={6}>
            <LinkContainer
              to={`/${i18n.language}${generatePath(t('routes:ticket.account_orders_order'),
                { orderSlug })}`}
            >
              <Button className="BackLink BackLinkBottom" variant="link">
                <ChevronLeftIcon />
                {' '}
                {t('button.back_to_tickets')}
              </Button>
            </LinkContainer>
          </Col>
          <Col md={6}>
            <div className="ButtonRight">
              <Button variant="primary" disabled={submitDisabled} type="submit">
                {t('button.save')}
                {isSubmitting && (
                  <span className="ButtonSpinner">
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </span>
                )}
              </Button>
            </div>
          </Col>
        </Row>
      </ValidatorForm>
    );
  }
}

export default withTranslation()(PersonalizeForm);

PersonalizeForm.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  ticket: PropTypes.shape({
    attendee: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      preposition: PropTypes.string,
      lastName: PropTypes.string.isRequired,
      token: PropTypes.string.isRequired,
    }),
    token: PropTypes.string.isRequired,
    hasDietPreference: PropTypes.bool.isRequired,
  }).isRequired,
  eventToken: PropTypes.string.isRequired,
  orderSlug: PropTypes.string.isRequired,
  attendees: PropTypes.arrayOf(PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    preposition: PropTypes.string,
    lastName: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
  })).isRequired,
};
