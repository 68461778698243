import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import PropTypes from 'prop-types';
import Dropdown from 'components/Input/Dropdown';
import Loader from 'components/Loader';
import '../Form.scss';
import ToastContent from 'components/ToastContent';
import {logError} from 'services/error-handling/ErrorHandler';
import {handleLegacyInternalApiCall, METHOD_GET,} from 'services/api-requests/ApiCallHandler';

class IdealForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      idealIssuers: [],
    };
  }

  componentDidMount() {
    this.loadIdealIssuers();
  }

  loadIdealIssuers = () => {
    const { t } = this.props;
    handleLegacyInternalApiCall('shop/payment-method/ideal/issuers',
      METHOD_GET,
      false,
      null,
      (response) => {
        const idealIssuers = response.data.map((option) => ({
          value: option.value,
          label: t(`ideal.select.${option.label}`),
        }));

        this.setState({
          idealIssuers,
          isLoading: false,
        });
      },
      (error) => {
        logError(error);
        toast.error(<ToastContent type="error" message={t('error.payment.get')} />);

        this.setState({ isLoading: false });
      });
  };

  handleChange = (event) => {
    const { onChange } = this.props;

    onChange(event.value);
  };

  render() {
    const { t, paymentService } = this.props;
    const { isLoading, idealIssuers } = this.state;

    if (isLoading) {
      return <Loader className="FormLoader" />;
    }

    const issuer = paymentService.get('ideal').getDetails();

    const selectedIssuer = issuer != null ? {
      value: issuer,
      label: idealIssuers.find(
        (idealIssuer) => idealIssuer.value === issuer,
      ).label,
    } : null;

    return (
      <Dropdown
        options={idealIssuers}
        placeholder={t('ideal.select.select_bank')}
        onChange={this.handleChange}
        value={selectedIssuer}
        menuPlacement="bottom"
        name="selectedIdealIssuer"
        onClick={(e) => {
          e.stopPropagation();
        }}
        menuPosition="fixed"
      />
    );
  }
}

export default withTranslation()(IdealForm);

IdealForm.propTypes = {
  t: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  paymentService: PropTypes.shape({
    get: PropTypes.func.isRequired,
  }).isRequired,
};
