import {useQueryClient} from '@tanstack/react-query';
import {toast} from 'react-toastify';
import {trackAddToCart} from 'services/tracking/TrackingHandler';
import {handleLegacyInternalApiCall, METHOD_POST} from 'services/api-requests/ApiCallHandler';
import LocalStorageService from 'services/storage/LocalStorageService';
import {logError} from 'services/error-handling/ErrorHandler';
import ToastContent from 'components/ToastContent';
import React from "react";
import {QUERY_KEY} from "./useGetCart";

interface UseChangeCartItemProps {
    eventToken: string;
    errorMessage: string;
}

export const useChangeCartItem = ({
                                    eventToken,
    errorMessage,
                                }: UseChangeCartItemProps) => {
    const queryClient = useQueryClient();

    const changeAmount = (token: string, newAmount: number) => {
        if (newAmount > 99) return;
        const task = newAmount === 0 ? 'remove' : 'edit';

        handleLegacyInternalApiCall(
            `shop/cart/${LocalStorageService.getCartUuid()}/event/${eventToken}/${task}/${token}`,
            METHOD_POST,
            false,
            { quantity: newAmount },
            () => {
                queryClient.invalidateQueries({ queryKey: [QUERY_KEY + eventToken] });
                trackAddToCart(token, newAmount);
            },
            (error) => {
                logError(error);
                toast.error(<ToastContent type="error" message={errorMessage} />);
            }
        );
    };

    const removeItem = (
        token: string,
        isVoucher: boolean,
    ) => {
        let url = `shop/cart/${LocalStorageService.getCartUuid()}/event/${eventToken}/remove/${token}`;

        if(isVoucher) {
            url = `shop/cart/${LocalStorageService.getCartUuid()}/event/${eventToken}/voucher/remove/${token}`;
        }

        handleLegacyInternalApiCall(
            url,
            METHOD_POST,
            false,
            null,
            () => {
                queryClient.invalidateQueries({ queryKey: [QUERY_KEY + eventToken] });
            },
            (error) => {
                logError(error);
                toast.error(<ToastContent type="error" message={errorMessage} />);
            }
        );
    };


    return { changeAmount, removeItem };
};