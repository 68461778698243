import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './Footer.scss';
import {SocialIcon} from 'react-social-icons';
import {useTranslation} from 'react-i18next';

export default () => {
  const { t } = useTranslation();

  const bgColor = '#2a2822';
  const fgColor = '#A9B7B4';
  const dimensions = 32;

  const year = new Date().getFullYear();

  return (
    <div className="Footer">
      <Container>
        <Row>
          <Col md={3} className="Copyright">
            <span className="InfoText">
              © Copyright
              {' '}
              {year}
            </span>
            <br />
            <a href="https://www.soulsurvivor.nl" target="_blank" rel="noopener noreferrer">Soul Survivor</a>
          </Col>
          <Col md={3}>
            <span className="InfoText">
              {t('termsAndPrivacy.conditions')}
            </span>
            <br />
            <a href={t('termsAndPrivacy.privacyStatement.url')} target="_blank" rel="noopener noreferrer">
              {t('termsAndPrivacy.privacyStatement.label')}
            </a>
            <br />
            <a href={t('termsAndPrivacy.termsAndConditions.url')} target="_blank" rel="noopener noreferrer">
              {t('termsAndPrivacy.termsAndConditions.label')}
            </a>
          </Col>
          <Col md={3}>
            <span className="InfoText">{t('help.need_help')}</span>
            <br />
            {/* <a href={t('help.faq.url')} target="_blank">{t('help.faq.label')}</a><br /> */}
            <a href={t('help.contact.url')} target="_blank" rel="noopener noreferrer">{t('help.contact.label')}</a>
          </Col>
          <Col md={1} />
          <Col md={2}>
            {t('footer.follow_us')}
            <div className="SocialMediaIcons">
              <SocialIcon
                url="https://www.youtube.com/soulsurvivorholland"
                bgColor={bgColor}
                fgColor={fgColor}
                style={{ height: dimensions, width: dimensions }}
                target="_blank"
                rel="noopener noreferrer"
              />
              <SocialIcon
                url="https://www.facebook.com/soulsurvivor/"
                bgColor={bgColor}
                fgColor={fgColor}
                style={{ height: dimensions, width: dimensions }}
                target="_blank"
                rel="noopener noreferrer"
              />
              <SocialIcon
                url="https://instagram.com/soulsurvivornl/"
                bgColor={bgColor}
                fgColor={fgColor}
                style={{ height: dimensions, width: dimensions }}
                target="_blank"
                rel="noopener noreferrer"
              />
            </div>

          </Col>
        </Row>
      </Container>
    </div>
  );
};
