import React, {Component} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {withTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import PropTypes from 'prop-types';
import EventHeader from 'components/EventHeader';
import DocumentTitle from 'components/DocumentTitle';
import Header from 'components/Header';
import Footer from 'components/Footer';
import FullScreenLoader from 'components/Loader/FullScreenLoader';

import './OrderYouthLeader.scss';
import ToastContent from 'components/ToastContent';
import {logError} from 'services/error-handling/ErrorHandler';
import {handleLegacyInternalApiCall, METHOD_GET} from 'services/api-requests/ApiCallHandler';
import LocalStorageService from 'services/storage/LocalStorageService';

class OrderYouthLeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: null,
      order: null,
      isLoading: true,
    };
  }

  componentDidMount() {
    const { t, match } = this.props;

    handleLegacyInternalApiCall(`shop/order/${match.params.orderSlug}`,
      METHOD_GET,
      false,
      null,
      (response) => {
        this.setState({
          event: response.data.event,
          order: response.data.order,
        });
      },
      (error) => {
        logError(error);
        toast.error(<ToastContent type="error" message={t('error.order.get')} />);
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { t, match } = this.props;
    const { event, order, isLoading } = this.state;

    if (isLoading) {
      return <FullScreenLoader route={match} />;
    }

    if (order.cartUuid === LocalStorageService.getCartUuid()) {
      window.location.reload();
      return <FullScreenLoader route={match} />;
    }

    return (
      <div className="OrderYouthLeader">
        <DocumentTitle
          title={`Soul Survivor ${event.name} | ${t('order.event.youthleader.placed')}`}
        />

        <Header route={match} />

        <div className="Main">
          <EventHeader
            name={event.name}
            description={event.description}
            date={event.date}
            fromDate={event.startDateTime}
            tillDate={event.endDateTime}
            venue={event.location}
            tickets={event.tickets}
            small
            websiteUrl={event.websiteUrl}
            mapsUrl={event.mapsUrl}
          />

          <Container>
            <Row>
              <Col lg={12} className="CheckOutColumn">
                <div className="DetailsBlock Block">
                  <h2>
                    {t('order.event.youthleader.title')}
                    {' '}
                    {event.name}
                    !
                  </h2>
                  <div className="BlockExplanation">
                    {t('order.event.youthleader.subtitle')}
                  </div>

                  {t('order.event.youthleader.description')}
                  <br />
                  <br />
                  {t('order.event.youthleader.order_number')}
                  <strong>{order.orderNumber}</strong>
                  .
                  <br />
                  {t('order.event.youthleader.email')}
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <Footer />
      </div>
    );
  }
}

export default withTranslation()(OrderYouthLeader);

OrderYouthLeader.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      orderSlug: PropTypes.string,
    }).isRequired,
  }).isRequired,
};
