import React, {useEffect, useState} from 'react';

import './Cart.scss';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {useTranslation} from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import VerifiedIcon from '@material-ui/icons/VerifiedUser';
import VoucherIcon from '@material-ui/icons/CardGiftcard';
import {LinkContainer} from 'react-router-bootstrap';
import {generatePath} from 'react-router-dom';
import VoucherForm from 'components/Form/VoucherForm';
import Loader from 'components/Loader';
import CartItem from 'components/Cart/CartItem';
import MoneyString from "../MoneyString";
import {GetCartQueryParams, useGetCart} from "../../hooks/Cart/useGetCart";
import queryString from "query-string";

type CartMobileProps = {
    image: string,
    eventSlug: string,
    eventToken: string,
}

export const CartMobile: React.FC<CartMobileProps> = ({image, eventSlug, eventToken}) => {
    useEffect(() => {
        return () => {
            document.body.classList.remove('CartOpened');
        };
    }, []);

    const {
        data: data,
        isLoading: isCartLoading,
        isFetching: isCartFetching,
        isError: isCartError,
    } = useGetCart({eventToken} as GetCartQueryParams);

    const {t, i18n} = useTranslation();

    if (isCartError) return <div>{t('error.cart.get')}</div>;

    const products = data?.products ?? [];
    const totalPrice = data?.totalPrice;
    const voucher = data?.voucher ?? [];

    const urlParams = queryString.parse(window.location.search);

    const [showVoucherInput, setShowVoucherInput] = useState(typeof urlParams.voucher !== 'undefined');

    const [isExpanded, setExpanded] = useState(false);

    let count = 0;

    products.forEach((product) => {
        count += product.quantity;
    });

    const handleToggle = () => {
        if (count > 0) {
            const newExpanded = !isExpanded;

            setExpanded(newExpanded);

            if (newExpanded) {
                document.body.classList.add('CartOpened');
            } else {
                document.body.classList.remove('CartOpened');
            }
        }
    };

    if (isExpanded && count > 0) {
        return (
            <div className="MobileCart MobileCartFull">
                <div className="CartHeader">
                    <Button className="CloseButton" variant="light" onClick={handleToggle}>
                        <CloseIcon/>
                    </Button>
                    <img src={image} alt=""/>
                </div>
                <div className="CartBody">
                    <h2>{t('cart.title')}</h2>
                    {isCartFetching && <Loader className="CartLoader"/>}
                    <div className={isCartFetching ? 'CartInner Loading' : 'CartInner'}>
                        <div className="CartContents">
                            {products.map((item) => (
                                <CartItem
                                    key={`cartitem-${item.token}`}
                                    name={item.name}
                                    varietyName={item.varietyName}
                                    quantity={item.quantity}
                                    originalPrice={item.originalPrice}
                                    unitPrice={item.unitPrice}
                                    totalPrice={item.totalPrice}
                                    discounts={item.discounts}
                                    vouchers={item.vouchers}
                                />
                            ))}
                        </div>
                        <Row className="CartTotal">
                            <Col className="CartTotalText" xs={8}>
                                <div className="CartTotalTextTotal">
                                    {t('cart.total')}
                                </div>
                                <div className="CartTotalTextDescription">
                                    {t('cart.includingVAT')}
                                </div>
                            </Col>
                            <Col className="CartTotalAmount" xs={4}>
                                <MoneyString
                                    amount={totalPrice.amount}
                                    currency={totalPrice.currency}
                                />
                            </Col>
                        </Row>

                        {(!showVoucherInput) && (
                            <div className="CartVoucherAdd">
                                <Button
                                    className="AddVoucherLink"
                                    variant="link"
                                    onClick={() => setShowVoucherInput(true)}
                                >
                                    <VoucherIcon/>
                                    {' '}
                                    {t('cart.addVoucher')}
                                </Button>
                            </div>
                        )}
                        {showVoucherInput && (
                            <VoucherForm
                                eventToken={eventToken}
                                toggleVoucherClick={() => setShowVoucherInput(false)}
                            />
                        )}
                    </div>

                    <div className={isCartLoading ? 'CartButtons Loading' : 'CartButtons'}>
                        <LinkContainer
                            to={`/${i18n.language}${generatePath(t('routes:ticket.cart'),
                                {eventSlug})}`}
                        >
                            <Button
                                variant="primary"
                                block
                                size="lg"
                                disabled={products.length === 0}
                            >
                                {t('event.button.buy_tickets')}
                            </Button>
                        </LinkContainer>
                    </div>

                    <div className="SafePayment">
                        <VerifiedIcon className="SafePaymentIcon"/>
                        {' '}
                        {t('cart.safePayment')}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div
            className="MobileCart MobileCartBar"
            onClick={handleToggle}
            onKeyDown={handleToggle}
            tabIndex={0}
            role="button"
        >
            <Container className={isCartLoading && 'Loading'}>
                <div className="CartBody">
                    <h2>{t('cart.title')}</h2>
                    {isCartLoading && <Loader className="CartLoader"/>}
                    {count > 0
                        ? (
                            <Row className="CartTotal">
                                <Col className="CartTotalText" xs={8}>
                                    <div className="CartTotalTextTotal">
                                        {count}
                                        {' '}
                                        {count === 1 ? t('cart.ticket') : t('cart.tickets')}
                                    </div>
                                </Col>
                                <Col className="CartTotalAmount" xs={4}>
                                    <MoneyString
                                        amount={totalPrice.amount}
                                        currency={totalPrice.currency}
                                    />
                                </Col>
                            </Row>
                        ) : (
                            <div className="CartContents">
                                {!isCartLoading && (
                                    <div className="CartEmpty">
                                        {t('cart.cart_is_empty')}
                                    </div>
                                )}
                            </div>
                        )}
                </div>
            </Container>
        </div>
    );
}

export default CartMobile;

//
// class CartMobile extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       isCartLoading: false,
//       isLoaded: false,
//       expanded: false,
//       products: [],
//       totalPrice: {currency: '', amount: ''},
//       count: 0,
//       showVoucherInput: false,
//     };
//   }
// }

//   render() {
//     const {
//       image, eventToken, eventSlug, t, i18n,
//     } = this.props;
//     const {
//       expanded, count, isCartLoading, products, showVoucherInput, totalPrice, isLoaded,
//     } = this.state;
//
//
// }
//
// export default withTranslation()(CartMobile);
//
// CartMobile.propTypes = {
//   t: PropTypes.func.isRequired,
//   i18n: PropTypes.shape({
//     language: PropTypes.string,
//   }).isRequired,
//   image: PropTypes.string.isRequired,
//   eventSlug: PropTypes.string.isRequired,
//   eventToken: PropTypes.string.isRequired,
//   update: PropTypes.bool.isRequired,
//   onUpdate: PropTypes.func.isRequired,
// };
