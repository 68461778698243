import {generatePath} from 'react-router-dom';
import * as types from './actionTypes';
import LocalStorageService from '../services/storage/LocalStorageService';
import {logError} from '../services/error-handling/ErrorHandler';
import i18n from '../i18n';
import {handleApiCall, METHOD_GET, METHOD_POST} from '../services/api-requests/ApiCallHandler';

export function loginSuccess() {
  return { type: types.LOG_IN_SUCCESS };
}

export function logInUserByToken(token) {
  LocalStorageService.clearToken();

  return function (dispatch) {
    return handleApiCall('/login/key', METHOD_POST, false, {
      key: token,
    }, (response) => {
      LocalStorageService.setToken({
        access_token: response.data.token,
        refresh_token: response.data.refreshToken,
      });

      handleApiCall('/me', METHOD_GET, true, null, (me) => {
        LocalStorageService.setUser(me.data);
        dispatch(loginSuccess());
      }, (error) => {
        logError(error);
      });
    }, (error) => {
      logError(error);
      if (error.response && error.response.status === 401) {
        if (error.response.data.error === 910) {
          window.location.href = `/${i18n.language}${generatePath(`${i18n.t(
            'routes:ticket.account_login',
          )}?e=key_invalid`)}`;
        } else if (error.response.data.error === 911) {
          window.location.href = `/${i18n.language}${generatePath(`${i18n.t(
            'routes:ticket.account_login',
          )}?e=key_expired`)}`;
        }
        return false;
      }
      return false;
    });
  };
}

export function logInUserByUsernameAndPassword(username, password, onError) {
  LocalStorageService.clearToken();

  return function (dispatch) {
    return handleApiCall('/login', METHOD_POST, false, {
      username,
      password,
    }, (response) => {
      LocalStorageService.setToken({
        access_token: response.data.token,
        refresh_token: response.data.refreshToken,
      });

      handleApiCall('/me', METHOD_GET, true, null, (me) => {
        LocalStorageService.setUser(me.data);
        dispatch(loginSuccess());
      }, (error) => {
        logError(error);
      });
    }, (error) => {
      dispatch(onError(error));
      return false;
    });
  };
}

export function logout() {
  LocalStorageService.clearToken();
  window.location.href = '/';
  return { type: types.LOG_OUT_SUCCESS };
}
