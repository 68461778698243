import React from 'react';
import PropTypes from 'prop-types';
import Header from 'components/Header/index';
import Footer from 'components/Footer/index';
import Loader from './index';

import './Loader.scss';

const FullScreenLoader = ({ route }) => (
  <div className="PageLoading">
    <Header route={route} />

    <div className="Main">
      <Loader className="PageLoader" />
    </div>

    <Footer />
  </div>
);

export default FullScreenLoader;

FullScreenLoader.propTypes = {
  route: PropTypes.shape({
    path: PropTypes.string,
  }),
};