import {handleLegacyInternalApiCall, METHOD_GET} from 'services/api-requests/ApiCallHandler';
import IdealPaymentMethod from 'services/payment/methods/IdealPaymentMethod';
import CreditcardPaymentMethod from 'services/payment/methods/CreditcardPaymentMethod';
import BancontactPaymentMethod from 'services/payment/methods/BancontactPaymentMethod';
import ApplePayPaymentMethod from 'services/payment/methods/ApplePayPaymentMethod';
import {logError} from 'services/error-handling/ErrorHandler';

export default class PaymentService {
  constructor() {
    this.method = null;
    this.methods = {};
  }

  getMethods(country, onSuccess, onError) {
    handleLegacyInternalApiCall('shop/payment-methods', METHOD_GET, false,
      { country }, (response) => {
        const paymentMethods = response.data;

        Object.values(paymentMethods).forEach((method) => {
          let object = null;

          if (method === 'ideal') {
            object = new IdealPaymentMethod();
          } else if (method === 'creditcard') {
            object = new CreditcardPaymentMethod();
          } else if (method === 'bancontact') {
            object = new BancontactPaymentMethod();
          } else if (method === 'applepay') {
            object = new ApplePayPaymentMethod();
          }

          if (object === null) {
            throw Error();
          }

          object.setup();

          this.methods[method] = object;
        });

        onSuccess(paymentMethods);
      },
      (error) => {
        logError(error);
        onError(error);
      });
  }

  setMethod(method) {
    this.method = method;
  }

  getCurrentMethodObject() {
    return this.methods[this.method];
  }

  isValid() {
    return this.getCurrentMethodObject().isValid();
  }

  handleSubmit(amount, callback) {
    this.getCurrentMethodObject().handleSubmit(amount, callback);
  }

  handlePayment(orderSlug, onSuccess, onError) {
    this.getCurrentMethodObject().handlePayment(orderSlug, onSuccess, onError);
  }

  setDetails(details) {
    this.getCurrentMethodObject().setDetails(details);
  }

  get(method) {
    return this.methods[method];
  }

  getDetails() {
    if (this.method === null) {
      return null;
    }

    return this.methods[this.method].getDetails();
  }

  getPaymentProfile() {
    return this.paymentProfile;
  }
}
