import React from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

import './Breadcrumbs.scss';
import {generatePath, Link} from 'react-router-dom';

const Breadcrumbs = ({
  active, employee, eventSlug,
}) => {
  const { t, i18n } = useTranslation();

  let classCart = '';
  let classDetails = '';
  let classPay = '';
  let classThanks = '';

  if (active === 'cart') {
    classCart = ' Active';
  }

  if (active === 'details') {
    classCart += ' Completed';
    classDetails = ' Active';
  }

  if (active === 'overview') {
    classCart += ' Completed';
    classDetails += ' Completed';
    classPay = ' Active';
  }

  if (active === 'pay') {
    classCart += ' Completed';
    classDetails += ' Completed';
    classPay = ' Active';
  }

  if (active === 'thanks') {
    classCart += ' Completed Disabled';
    classDetails += ' Completed Disabled';
    classPay += ' Completed Disabled';
    classThanks = ' Active';
  }

  return (
    <div className="Breadcrumbs">
      {employee !== true && (
        <Link
          to={`/${i18n.language}${generatePath(t('routes:ticket.cart'),
            { eventSlug })}`}
          className={`Breadcrumb${classCart}`}
        >
          {t('order.process.cart')}
        </Link>
      )}

      {employee !== true && (
        <span className="BreadcrumbDivider">
          <ChevronRightIcon className="BreadcrumbDividerIcon" />
        </span>
      )}

      <Link
        to={`/${i18n.language}${generatePath(t('routes:ticket.checkout'),
          { eventSlug })}`}
        className={`Breadcrumb${classDetails}`}
      >
        {t('order.process.details')}
      </Link>

      <span className="BreadcrumbDivider">
        <ChevronRightIcon className="BreadcrumbDividerIcon" />
      </span>

      <span className={`Breadcrumb${classPay}`}>
        {t('order.process.payment')}
      </span>

      <span className="BreadcrumbDivider">
        <ChevronRightIcon className="BreadcrumbDividerIcon" />
      </span>

      <span className={`Breadcrumb${classThanks}`}>
        {t('order.process.thanks')}
      </span>
    </div>
  );
};

export default Breadcrumbs;

Breadcrumbs.propTypes = {
  active: PropTypes.string.isRequired,
  eventSlug: PropTypes.string.isRequired,
  employee: PropTypes.bool,
};