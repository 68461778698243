import React from 'react';
import PropTypes from 'prop-types';

import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {useTranslation} from 'react-i18next';
import {classNames} from '../../util';
import './ToastContent.scss';
import ErrorCodeList from './ErrorCodes';

const ToastContent = ({
  type, icon, code, message,
}) => {
  let DisplayIcon = InfoIcon;
  let content = message;
  const { t } = useTranslation();

  if (code !== undefined) {
    content = t(ErrorCodeList[code]);
  }

  if (icon !== undefined) {
    DisplayIcon = icon;
  } else if (type === 'error') {
    DisplayIcon = ErrorIcon;
  } else if (type === 'warning') {
    DisplayIcon = WarningIcon;
  } else if (type === 'success') {
    DisplayIcon = CheckCircleIcon;
  } else if (type === 'info') {
    DisplayIcon = InfoIcon;
  }

  return (
    <div className={classNames('ToastContent', type)}>
      <div className="ToastContentIcon">
        <DisplayIcon />
      </div>
      <div className="ToastContentMessage">
        {content}
      </div>
    </div>
  );
};

export default ToastContent;

ToastContent.propTypes = {
  type: PropTypes.string.isRequired,
  icon: PropTypes.string,
  code: PropTypes.number,
  message: PropTypes.string,
};