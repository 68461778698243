import React from 'react';
import Container from 'react-bootstrap/Container';

import './LargeHeader.scss';
import PropTypes from 'prop-types';

const LargeHeader = ({
  title, children,
}) => (
  <div className="LargeHeader">
    <Container>
      <h1 className="Title">{title}</h1>
      <div className="LargeHeaderInformationBar">
        {children}
      </div>
    </Container>
  </div>
);

export default LargeHeader;

LargeHeader.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};