import React from 'react';
import ReactDOM from 'react-dom/client';
import {applyMiddleware, createStore} from 'redux';
import {Provider} from 'react-redux';
import * as Sentry from '@sentry/browser';
import thunk from 'redux-thunk';
import App from 'components/App';
import reducers from './reducers/rootReducer';
import {getEnvironment, isProductionEnvironment} from './util';
import {QueryClient} from "@tanstack/react-query";
import {createSyncStoragePersister} from "@tanstack/query-sync-storage-persister";
import {PersistQueryClientProvider} from "@tanstack/react-query-persist-client";

if (isProductionEnvironment()) {
    Sentry.init({
        dsn: 'https://0a669b04bca7461baf56c069990a2c52@sentry.io/1812703',
        environment: getEnvironment(),
    });
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: (failureCount, error: any) => {
                console.log(error);
                if (error.response && (error.response.status === 404 || error.response.status === 400)) {
                    return false;
                }
                return failureCount < 3;
            },
        },
    },
})
const persister = createSyncStoragePersister({
    storage: window.localStorage,
})

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);
const store = createStoreWithMiddleware(reducers);

root.render(
    <PersistQueryClientProvider client={queryClient} persistOptions={{persister}}>
        <Provider store={store}>
            <App/>
        </Provider>,
    </PersistQueryClientProvider>
);
