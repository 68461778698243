import React from 'react';
import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';
import {useTranslation} from 'react-i18next';

import './ConfirmDialog.scss';
import PropTypes from 'prop-types';

const TicketDownloadConfirmDialog = ({ cancel, confirm }) => {
  const { t } = useTranslation();

  return (
    <div className="ConfirmDialogWrapper">
      <Popover
        className="ConfirmDialog TicketDownloadConfirmDialog"
        id="popover-basic"
        placement="auto"
        outOfBoundaries
      >
        <h3>{t('personalize.download.not_all_personalized')}</h3>
        <div className="ConfirmDialogText">
          {t('personalize.download.question')}
        </div>
        <div className="ConfirmButtons">
          <Button variant="light" onClick={cancel}>
            {t('personalize.download.button.cancel')}
          </Button>
          <Button variant="success" onClick={confirm}>
            {t('personalize.download.button.download')}
          </Button>
        </div>
      </Popover>
    </div>
  );
};

export default TicketDownloadConfirmDialog;

TicketDownloadConfirmDialog.propTypes = {
  cancel: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
};
