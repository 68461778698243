import React, {Component} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {withTranslation} from 'react-i18next';
import Moment from 'react-moment';
import {toast} from 'react-toastify';
import PropTypes from 'prop-types';
import EventHeader from 'components/EventHeader';
import DocumentTitle from 'components/DocumentTitle';
import Breadcrumbs from 'components/Breadcrumbs';
import './Pay.scss';
import PaymentForm from 'components/Form/Payment/PaymentForm';
import NeedHelpBlock from 'components/NeedHelpBlock';
import Header from 'components/Header';
import Footer from 'components/Footer';
import FullScreenLoader from 'components/Loader/FullScreenLoader';
import ToastContent from 'components/ToastContent';
import {logError} from 'services/error-handling/ErrorHandler';
import {handleLegacyInternalApiCall, METHOD_GET} from 'services/api-requests/ApiCallHandler';

class Pay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: null,
      order: null,
      isLoading: true,
    };
  }

  componentDidMount() {
    const { t, match } = this.props;

    handleLegacyInternalApiCall(`shop/order/${match.params.orderSlug}`,
      METHOD_GET,
      false,
      null,
      (response) => {
        this.setState({
          event: response.data.event,
          order: response.data.order,
          isLoading: false,
        });
      },
      (error) => {
        logError(error);
        toast.error(<ToastContent type="error" message={t('error.order.get')} />);
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { t, i18n, match } = this.props;
    const { event, order, isLoading } = this.state;

    if (isLoading) {
      return <FullScreenLoader route={match} />;
    }

    return (
      <div className="Pay">
        <Header route={match} />

        <div className="Main">
          <EventHeader
            name={event.name}
            description={event.description}
            date={event.date}
            fromDate={event.startDateTime}
            tillDate={event.endDateTime}
            venue={event.location}
            tickets={event.tickets}
            small
            websiteUrl={event.websiteUrl}
            mapsUrl={event.mapsUrl}
          />

          <Container>
            <Breadcrumbs
              active="pay"
              eventSlug={event.slug}
              eventUrl={event.url}
            />

            <Row>
              <Col lg={7} className="CheckOutColumn">
                <div className="DetailsBlock Block">
                  {order.canBePaid ? (
                    <div>
                      {order.hasPayments ? (
                        <div>
                          <DocumentTitle
                            title={`Soul Survivor ${event.name} | ${t(
                              'order.event.failed.failed',
                            )}`}
                          />
                          <h2>{t('order.event.failed.title')}</h2>
                          <div className="BlockExplanation">
                            {t('order.event.failed.subtitle')}
                          </div>

                          <div className="PayText">
                            {t('order.event.failed.try_again')}
                            <br />
                            {t('order.event.pay.send_to_someone')}
                            <br />
                            <strong>{t('order.event.failed.expires_cancelled')}</strong>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <DocumentTitle
                            title={`Soul Survivor ${event.name} | ${t(
                              'order.event.pay.title',
                            )}`}
                          />
                          <h2>{t('order.event.pay.title')}</h2>
                          <div className="BlockExplanation">
                            {t('order.event.pay.subtitle')}
                          </div>

                          <div className="PayText">
                            {t('order.event.pay.send_to_someone')}
                            <br />
                            <strong>{t('order.event.pay.expires_cancelled')}</strong>
                          </div>
                        </div>
                      )}

                      <div className="PaymentDue">
                        {t('order.event.pay.expires_on')}
                        {' '}
                        <Moment
                          date={order.expiresAt}
                          locale={i18n.language}
                          format="D MMM YYYY, H:mm"
                        />
                        .
                      </div>

                      <hr className="DetailsDivider" />

                      {order.hasPayments ? (
                        <div>
                          <h2>{t('order.event.failed.pay_again.title')}</h2>

                          <div className="BlockExplanation">
                            {t('order.event.failed.pay_again.subtitle')}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <h2>{t('order.event.overview.payment.title')}</h2>

                          <div className="BlockExplanation">
                            {t('order.event.overview.payment.subtitle')}
                          </div>
                        </div>
                      )}

                      <PaymentForm
                        country={order.country}
                        eventToken={event.token}
                        orderSlug={match.params.orderSlug}
                        type="retry"
                        terms={false}
                        amount={order.totalPriceIncludingVat}
                      />
                    </div>
                  ) : (
                    <div>
                      <DocumentTitle title={`Soul Survivor ${event.name}`} />

                      <h2>{t('order.event.pay.not_allowed.title')}</h2>
                      <div className="BlockExplanation">
                        {t('order.event.pay.not_allowed.subtitle')}
                      </div>
                      <div>
                        {t('order.event.pay.not_allowed.text')}
                      </div>
                    </div>
                  )}
                </div>
              </Col>
              <Col lg={5} className="CheckOutColumn">
                <NeedHelpBlock />
              </Col>
            </Row>
          </Container>
        </div>

        <Footer />
      </div>
    );
  }
}

export default withTranslation()(Pay);

Pay.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      orderSlug: PropTypes.string,
    }).isRequired,
  }).isRequired,
};
