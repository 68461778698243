import React from 'react';
import Container from 'react-bootstrap/Container';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import Header from 'components/Header';
import Footer from 'components/Footer';
import LargeHeader from 'components/LargeHeader';
import DocumentTitle from 'components/DocumentTitle';
import ChangePasswordForm from 'components/Form/ChangePasswordForm';

const AccountChangePassword = ({ t, match }) => (
  <div className="Account AccountOrders">
    <DocumentTitle title={`Soul Survivor | ${t('menu.change_password')}`} />
    <Header route={match} />
    <LargeHeader title={t('menu.change_password')} />

    <div className="Main">

      <Container>
        <div className="Block">
          <ChangePasswordForm />
        </div>
      </Container>
    </div>

    <Footer />
  </div>
);

export default withTranslation()(AccountChangePassword);

AccountChangePassword.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string,
  }),
};