import React, {Component} from 'react';
import Container from 'react-bootstrap/Container';
import {withTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import Header from 'components/Header';
import Footer from 'components/Footer';
import FullScreenLoader from 'components/Loader/FullScreenLoader';
import ToastContent from 'components/ToastContent';
import {logError} from 'services/error-handling/ErrorHandler';
import LargeHeader from 'components/LargeHeader';
import './AccountTicketPersonalize.scss';
import PersonalizeForm from 'components/Form/PersonalizeForm';
import DocumentTitle from 'components/DocumentTitle';
import {handleLegacyInternalApiCall, METHOD_GET} from 'services/api-requests/ApiCallHandler';

class AccountTicketPersonalize extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: null,
      ticket: null,
      attendees: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    this.setState({ isLoading: true });

    this.getOrder(match.params.orderSlug);
  }

  getOrder = (orderSlug) => {
    const { t } = this.props;
    handleLegacyInternalApiCall(`shop/order/${orderSlug}`, METHOD_GET, true, null, (response) => {
      this.setState({
        order: response.data,
      });

      this.getVisitors(orderSlug);
    }, (error) => {
      logError(error);
      toast.error(<ToastContent type="error" message={t('error.order.get')} />);
      this.setState({ isLoading: false });
    });
  };

  getTicket = (ticketSlug) => {
    const { t } = this.props;
    handleLegacyInternalApiCall(`ticket/${ticketSlug}`,
      METHOD_GET,
      true,
      null,
      (response) => {
        this.setState({
          ticket: response.data,
          isLoading: false,
        });
      },
      (error) => {
        logError(error);
        toast.error(<ToastContent type="error" message={t('error.ticket.get')} />);
        this.setState({ isLoading: false });
      });
  };

  getVisitors = (orderSlug) => {
    const { t, match } = this.props;
    handleLegacyInternalApiCall(`order/${orderSlug}/attendees`,
      METHOD_GET,
      true,
      null,
      (response) => {
        this.setState({
          attendees: response.data,
        });

        this.getTicket(match.params.ticketSlug);
      },
      (error) => {
        logError(error);
        toast.error(<ToastContent type="error" message={t('error.attendees.get')} />);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { t, i18n, match } = this.props;
    const {
      isLoading, ticket, order, attendees,
    } = this.state;

    if (isLoading === true) return <FullScreenLoader route={match} />;

    if (ticket.isPersonalizable === false) {
      toast.error(<ToastContent
        type="error"
        message={t('error.personalize.not_personalizable')}
      />);
    }

    return (
      <div className="Account AccountOrdersOrder">
        <DocumentTitle
          title={`Soul Survivor | ${ticket.attendee === null ? t(
            'personalize.header_assign',
          ) : t('personalize.header_edit')}`}
        />
        <Header route={match} />
        <LargeHeader
          title={(ticket.attendee === null ? `${t('personalize.header_assign')} – ${order.event.name}` : `${t(
            'personalize.header_edit',
          )} – ${order.event.name}`)}
        />

        <div className="Main">

          <Container>
            <Row>
              <Col lg={7}>
                <div className="Block">
                  <h2>{ticket.name}</h2>
                  <div className="BlockExplanation">
                    <Moment
                      date={ticket.validFrom}
                      locale={i18n.language}
                      format="D MMM YYYY"
                    />
                    {ticket.validFrom !== ticket.validTill && (
                      <span>
&nbsp;
                        {t('event.date_till')}
                        {' '}
                        <Moment
                          date={ticket.validTill}
                          locale={i18n.language}
                          format="D MMM YYYY"
                        />
                      </span>
                    )}
                  </div>
                  <p className="BlockExtraText">
                    {t('personalize.instructions')}
                  </p>

                  <PersonalizeForm
                    eventToken={order.event.token}
                    orderSlug={match.params.orderSlug}
                    ticket={ticket}
                    attendees={attendees}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <Footer />
      </div>
    );
  }
}

export default withTranslation()(AccountTicketPersonalize);

AccountTicketPersonalize.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      ticketSlug: PropTypes.string,
      orderSlug: PropTypes.string,
    }).isRequired,
  }).isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
};
