import React, {Component} from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {withTranslation} from 'react-i18next';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {toast} from 'react-toastify';
import PropTypes from 'prop-types';
import EventHeader from 'components/EventHeader';
import DocumentTitle from 'components/DocumentTitle';
import Breadcrumbs from 'components/Breadcrumbs';
import './PaymentExpired.scss';
import Header from 'components/Header';
import Footer from 'components/Footer';
import FullScreenLoader from 'components/Loader/FullScreenLoader';
import ToastContent from 'components/ToastContent';
import {logError} from 'services/error-handling/ErrorHandler';
import {handleLegacyInternalApiCall, METHOD_GET} from 'services/api-requests/ApiCallHandler';

class PaymentExpired extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: null,
      isLoading: true,
    };
  }

  componentDidMount() {
    const { t, match } = this.props;

    handleLegacyInternalApiCall(`shop/order/${match.params.orderSlug}`,
      METHOD_GET,
      false,
      null,
      (response) => {
        this.setState({
          event: response.data.event,
          isLoading: false,
        });
      },
      (error) => {
        logError(error);
        toast.error(<ToastContent type="error" message={t('error.order.get')} />);
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { t, match } = this.props;
    const { event, isLoading } = this.state;

    if (isLoading) {
      return <FullScreenLoader route={match} />;
    }

    return (
      <div className="PaymentExpired">
        <DocumentTitle
          title={`Soul Survivor ${event.name} | ${t('order.event.expired.expired')}`}
        />

        <Header route={match} />

        <div className="Main">
          <EventHeader
            name={event.name}
            description={event.description}
            date={event.date}
            fromDate={event.startDateTime}
            tillDate={event.endDateTime}
            venue={event.location}
            tickets={event.tickets}
            small
            websiteUrl={event.websiteUrl}
            mapsUrl={event.mapsUrl}
          />

          <Container>
            <Breadcrumbs
              active="pay"
              eventSlug={event.slug}
              eventUrl={event.url}
            />

            <Row>
              <Col lg={12}>
                <div className="DetailsBlock Block">
                  <h2>{t('order.event.expired.title')}</h2>
                  <div className="BlockExplanation">
                    {t('order.event.expired.subtitle')}
                  </div>

                  <div className="PaymentExpiredText">
                    {t('order.event.expired.description')}
                  </div>

                  <div className="Buttons">
                    <Button href={event.url} variant="light">
                      <ChevronLeftIcon />
                      {' '}
                      {t('button.back_to_tickets')}
                    </Button>
                  </div>

                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <Footer />
      </div>
    );
  }
}

export default withTranslation()(PaymentExpired);

PaymentExpired.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      orderSlug: PropTypes.string,
    }).isRequired,
  }).isRequired,
};
