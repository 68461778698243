import React from 'react';

import {Router} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import LocalizedRouter from 'components/LocalizedRouter';
import TicketRoutes from 'routes/TicketRoutes';
import ScrollToTop from 'components/ScrollToTop';
import 'scss/index.scss';
import './App.scss';
import FullScreenLoader from 'components/Loader/FullScreenLoader';
import {useGetCartUuid} from "../../hooks/Cart/useGetCartUuid";
import ErrorPage from "../../pages/Error/ErrorPage";
import {useTranslation} from "react-i18next";

export const App: React.FC = () => {
    const {
        isLoading: isLoading,
        isError: isError
    } = useGetCartUuid();

    const {t} = useTranslation();

    if (isLoading) {
        return <FullScreenLoader/>;
    }

    if (isError) return <ErrorPage title={t('error.title')} text={t('error.cart.get')}/>;

    return (
        <div className="App">
            <LocalizedRouter
                RouterComponent={Router}
                languages={['en', 'nl']}
            >
                <ScrollToTop/>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    draggable={false}
                    pauseOnHover
                />
                <TicketRoutes/>
            </LocalizedRouter>
        </div>
    );
}

export default App;