import {useQuery} from "@tanstack/react-query";
import {handleInternalApiCall, METHOD_GET} from "../../services/api-requests/ApiCallHandler";
import LocalStorageService from 'services/storage/LocalStorageService';

const QUERY_KEY = 'cartUuid';

const fetchCartUuid = async (): Promise<string> => {
    if(LocalStorageService.hasCartUuid()) {
        return LocalStorageService.getCartUuid();
    }

    const response = await handleInternalApiCall(
        'shop/cart/uuid/',
        METHOD_GET,
        false,
        null
    );

    LocalStorageService.setCartUuid(response.data.cart_uuid);

    return response.data.cart_uuid;
};

export const useGetCartUuid = () => {
    return useQuery<string, Error>({
        queryKey: [QUERY_KEY],
        queryFn: fetchCartUuid
    });
};