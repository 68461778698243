import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';
import '../Form.scss';
import ToastContent from 'components/ToastContent';
import {logError} from 'services/error-handling/ErrorHandler';
import {handleLegacyInternalApiCall, METHOD_GET,} from 'services/api-requests/ApiCallHandler';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

const mollieSettings = {
  styles: {
    base: {
      backgroundColor: '#f4f4f4',
      color: '#495057',
    },
    isValid: {
      backgroundColor: '#f4f4f4',
      color: '#495057',
    },
    isInvalid: {
      backgroundColor: '#f4f4f4',
      color: '#495057',
    },
  },
};

class CreditCardForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      mollie: null,
      errors: {
        cardHolder: null,
        cardNumber: null,
        expiryDate: null,
        verificationCode: null,
      },
    };
  }

  componentDidMount() {
    this.loadMollieDetails();
  }

  handleMollieInput = (type, event) => {
    const { errors, mollie } = this.state;
    const { paymentService, onChange } = this.props;
    let error = null;

    if (event.error && event.touched) {
      error = event.error;
    }

    const newErrors = errors;
    newErrors[type] = error;
    const valid = (
      newErrors.cardHolder === null
      && newErrors.cardNumber === null
      && newErrors.expiryDate === null
      && newErrors.verificationCode === null
    );

    paymentService.get('creditcard').setValid(valid);

    this.setState({
      errors: newErrors,
    }, () => {
      onChange('creditcard', {
        mollie,
        errors: valid,
      });
    });
  };

  loadMollieDetails = () => {
    const { t, paymentService } = this.props;
    handleLegacyInternalApiCall('shop/payment-profile',
      METHOD_GET,
      false,
      null,
      (response) => {
        /* global Mollie */
        /* eslint no-undef: "error" */
        const mollie = Mollie(response.data.profile.id,
          { locale: 'nl_NL', testmode: response.data.profile.testMode });
        paymentService.get('creditcard').setMollieObject(mollie);
        this.setState({
          mollie,
          isLoading: false,
        }, () => {
          const cardHolder = mollie.createComponent('cardHolder', mollieSettings);
          cardHolder.mount('#card-holder');
          cardHolder.addEventListener('change', (event) => {
            this.handleMollieInput('cardHolder', event);
          });

          const cardNumber = mollie.createComponent('cardNumber', mollieSettings);
          cardNumber.mount('#card-number');
          cardNumber.addEventListener('change', (event) => {
            this.handleMollieInput('cardNumber', event);
          });

          const expiryDate = mollie.createComponent('expiryDate', mollieSettings);
          expiryDate.mount('#expiry-date');
          expiryDate.addEventListener('change', (event) => {
            this.handleMollieInput('expiryDate', event);
          });

          const verificationCode = mollie.createComponent('verificationCode', mollieSettings);
          verificationCode.mount('#verification-code');
          verificationCode.addEventListener('change', (event) => {
            this.handleMollieInput('verificationCode', event);
          });
        });
      },
      (error) => {
        logError(error);
        toast.error(<ToastContent type="error" message={t('error.payment.get')} />);

        this.setState({ isLoading: false });
      });
  };

  render() {
    const { t } = this.props;
    const { isLoading, errors } = this.state;

    if (isLoading) {
      return <Loader className="FormLoader" />;
    }

    return (
      <Form as="div" className="CreditCardForm">
        <Form.Row>
          <Form.Group as={Col} className="Input">
            <label className="label" htmlFor="card-holder">{t('creditcard.card_holder')}</label>
            <div id="card-holder" />
            {errors.cardHolder && <Form.Text className="InputError">{errors.cardHolder}</Form.Text>}
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} className="Input">
            <label className="label" htmlFor="card-number">{t('creditcard.card_number')}</label>
            <div id="card-number" />
            {errors.cardNumber && <Form.Text className="InputError">{errors.cardNumber}</Form.Text>}
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} className="Input">
            <label className="label" htmlFor="expiry-date">{t('creditcard.expiry_date')}</label>
            <div id="expiry-date" />
            {errors.expiryDate && <Form.Text className="InputError">{errors.expiryDate}</Form.Text>}
          </Form.Group>

          <Form.Group as={Col} className="Input">
            <label className="label" htmlFor="verification-code">{t('creditcard.verification_code')}</label>
            <div id="verification-code" />
            {errors.verificationCode && <Form.Text className="InputError">{errors.verificationCode}</Form.Text>}
          </Form.Group>
        </Form.Row>
      </Form>
    );
  }
}

export default withTranslation()(CreditCardForm);

CreditCardForm.propTypes = {
  t: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  paymentService: PropTypes.shape({
    get: PropTypes.func.isRequired,
  }).isRequired,
};
