import React from 'react';
import {useTranslation} from 'react-i18next';

import './NeedHelpBlock.scss';
import {Col, Row} from 'react-bootstrap';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import {LogoMessenger} from 'components/Logo';

export default () => {
  const { t } = useTranslation();

  return (
    <div className="DetailsBlock ContactMethodsBlock Block">
      <h2>{t('order.event.needhelp.title')}</h2>

      <div>
        {t('order.event.needhelp.text')}
      </div>
      <div className="ContactMethods">
        <Row className="ContactMethod" noGutters>
          <Col xs={1}>
            <a href={t('contact.email.link')}>
              <MailOutlineIcon />
            </a>
          </Col>
          <Col>
            <div className="ContactMethodText">
              <a href={t('contact.email.link')}>{t('contact.email.text')}</a>
            </div>
            <div className="ContactMethodSubText">
              {t('contact.email.subtext')}
            </div>
          </Col>
        </Row>
        <Row className="ContactMethod" noGutters>
          <Col xs={1}>
            <a href={t('contact.messenger.link')} target="_blank" rel="noopener noreferrer">
              <LogoMessenger />
            </a>
          </Col>
          <Col>
            <div className="ContactMethodText">
              <a
                href={t('contact.messenger.link')}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('contact.messenger.text')}
              </a>
            </div>
            <div className="ContactMethodSubText">
              {t('contact.messenger.subtext')}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
