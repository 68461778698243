import React, {Component} from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {ValidatorForm} from 'react-form-validator-core';
import {withTranslation} from 'react-i18next';
import axios from 'axios';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {generatePath, Redirect} from 'react-router-dom';
import {LinkContainer} from 'react-router-bootstrap';
import moment from 'moment';
import {toast} from 'react-toastify';
import PropTypes from 'prop-types';
import Input from 'components/Input/index';
import FormItem from 'components/Form/FormItem';
import RadioGroup from 'components/RadioGroup/index';
import Checkbox from 'components/Input/Checkbox';
import countries from 'components/Form/countries.json';
import Loader from 'components/Loader';

import './Form.scss';
import ToastContent from 'components/ToastContent';
import {logError} from 'services/error-handling/ErrorHandler';
import {handleLegacyInternalApiCall, METHOD_GET, METHOD_POST,} from 'services/api-requests/ApiCallHandler';
import LocalStorageService from 'services/storage/LocalStorageService';
import {trackAddPaymentInfo} from 'services/tracking/TrackingHandler';
import {classNames} from '../../util';
import Dropdown from '../Input/Dropdown';

class DetailsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        firstName: '',
        lastName: '',
        email: '',
        street: '',
        houseNumber: '',
        postcode: '',
        city: '',
        country: 'NL',
        countryOther: {},
        birthdate: '',
        phoneNumber: '',
        businessName: '',
        preposition: '',
        houseNumberAddition: '',
        church: '',
        newsletter: false,
      },
      visitedFields: {},
      changedFieldsSinceFormSubmission: {},
      validation: {},
      addressLoaded: false,
      autoPopulatedAddress: false,
      cancelTokenSource: null,
      typingAddressTimeOut: null,
      isLoading: true,
      isSaved: false,
      submitDisabled: false,
    };

    window.onbeforeunload = function () {
      return true;
    };
  }

  componentDidMount() {
    ValidatorForm.addValidationRule('isDate', (value) => {
      const regex = /^([0-2][0-9]|(3)[0-1])(-)(((0)[0-9])|((1)[0-2]))(-)\d{4}$/i;
      return regex.test(value);
    });

    ValidatorForm.addValidationRule('isSelected', (value) => {
      if (value === undefined) return false;
      if (value === null) return false;

      return value.value !== '' && value.value !== null;
    });

    this.getAddressDetails();
  }

  getAddressDetails = () => {
    const { t, eventToken } = this.props;
    handleLegacyInternalApiCall(
      `shop/cart/${LocalStorageService.getCartUuid()}/event/${eventToken}/order-details/address`,
      METHOD_GET,
      false,
      null,
      (response) => {
        const { data } = response;

        if (!Array.isArray(data)) {
          const birthdate = moment(data.birthdate, 'YYYY-MM-DD');
          if (typeof data.birthdate !== 'undefined') {
            data.birthdate = birthdate.format('DD-MM-YYYY');
          }

          const { phoneNumber } = data;
          if (typeof phoneNumber !== 'undefined') {
            data.phoneNumber = phoneNumber.replace(/ /g, '');
          }

          if (data.country == null || data.country === '') {
            data.country = 'NL';
          }

          Object.keys(data).forEach((key) => {
            if (data[key] === null) {
              data[key] = '';
            }
          });

          this.setState({
            isLoading: false,
            data,
          });
        } else {
          this.setState({
            isLoading: false,
          });
        }
      },
      (error) => {
        logError(error);
        toast.error(
          <ToastContent type="error" message={t('error.details.get')} />,
          {
            position: 'top-center',
            autoClose: false,
          },
        );

        this.setState({ isLoading: false });
      },
    );
  };

  handleChange = (event, callback = () => {}) => {
    const { data, changedFieldsSinceFormSubmission } = this.state;
    this.setState(
      {
        data: {
          ...data,
          [event.target.name]: event.target.value,
        },
        changedFieldsSinceFormSubmission: {
          ...changedFieldsSinceFormSubmission,
          [event.target.name]: true,
        },
        validation: {
          [event.target.name]: false,
        },
      },
      callback,
    );
  };

  loadAddress = (postcode, housenumber, source) => {
    const { t } = this.props;
    const { data } = this.state;

    handleLegacyInternalApiCall(
      'post-code/retrieve',
      METHOD_POST,
      false,
      {
        postcode,
        house_number: housenumber,
      },
      (response) => {
        if (response.data.street !== undefined) {
          this.setState({
            addressLoaded: true,
            autoPopulatedAddress: true,
            data: {
              ...data,
              street: response.data.street,
              city: response.data.city,
            },
          });
        } else {
          this.setState({
            addressLoaded: true,
            autoPopulatedAddress: false,
            data: {
              ...data,
              street: '',
              city: '',
            },
          });
        }
      },
      (error) => {
        logError(error);
        toast.error(
          <ToastContent type="error" message={t('error.postcode')} />,
        );

        this.setState({
          addressLoaded: true,
          autoPopulatedAddress: false,
        });
      },
      { cancelToken: source.token },
    );
  };

  handlePostcodeHouseNumberChange = (event) => {
    const { cancelTokenSource, typingAddressTimeOut, data } = this.state;
    const regex = /^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i;

    const { CancelToken } = axios;
    const source = CancelToken.source();

    if (cancelTokenSource) cancelTokenSource.cancel();
    if (typingAddressTimeOut) clearTimeout(typingAddressTimeOut);

    const newEvent = event;

    if (event.target.name === 'postcode') {
      newEvent.target.value = event.target.value.toUpperCase();
    }
    if (event.target.name === 'houseNumber') {
      newEvent.target.value = event.target.value.replace(/\D/g, '');
    }

    this.handleChange(newEvent, () => {
      if (
        data.houseNumber !== null
        && data.houseNumber !== ''
        && data.country === 'NL'
        && regex.test(data.postcode)
      ) {
        const self = this;
        this.setState({
          addressLoaded: false,
          cancelTokenSource: source,
          typingAddressTimeOut: setTimeout(() => {
            self.loadAddress(
              self.state.data.postcode,
              self.state.data.houseNumber,
              source,
            );
          }, 1000),
        });
      }
    });
  };

  handleCountrySelectChange = (event) => {
    this.handleChange({
      target: {
        name: 'country',
        value: event.value,
      },
    });
  };

  handleFieldVisit = (event) => {
    const { visitedFields } = this.state;
    this.setState({
      visitedFields: {
        ...visitedFields,
        [event.target.name]: true,
      },
    });
  };

  handleSubmit = (event) => {
    const { t, eventToken } = this.props;
    const { data } = this.state;
    event.preventDefault();

    window.onbeforeunload = null;
    this.setState({
      submitDisabled: true,
    });
    if (this.form.isFormValid()) {
      const birthdate = moment(data.birthdate, 'DD-MM-YYYY');

      handleLegacyInternalApiCall(
        `shop/cart/${LocalStorageService.getCartUuid()}/event/${eventToken}/order-details/address`,
        METHOD_POST,
        false,
        {
          firstName: data.firstName,
          preposition: data.preposition,
          lastName: data.lastName,
          country: data.country,
          countryOther: data.countryOther,
          postcode: data.postcode,
          houseNumber: data.houseNumber,
          houseNumberAddition: data.houseNumberAddition,
          street: data.street,
          city: data.city,
          email: data.email,
          phoneNumber: data.phoneNumber,
          newsletter: data.newsletter,
          birthdate: birthdate.format('YYYY-MM-DD'),
          church: data.church,
        },
        () => {
          this.setState({
            isSaved: true,
          });
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            this.setState({
              validation: error.response.data.fields,
            });
          } else {
            logError(error);
            toast.error(
              <ToastContent type="error" message={t('error.details.post')} />,
            );
          }
          this.setState({
            submitDisabled: false,
          });
        },
      );
    }

    return false;
  };

  handleManualAddress = () => {
    this.setState({
      autoPopulatedAddress: false,
    });
  };

  render() {
    const {
      employee, eventSlug, i18n, t,
    } = this.props;
    const {
      addressLoaded,
      autoPopulatedAddress,
      data,
      isLoading,
      isSaved,
      validation,
      submitDisabled,
    } = this.state;

    const required = t('form.validation.required');
    const invalid = t('form.validation.invalid');

    const translatedCountries = countries.map((country) => {
      const newCountry = country;
      newCountry.label = t(country.label);

      return newCountry;
    });

    const hideAddress = addressLoaded && autoPopulatedAddress;
    let disableAddress = ((addressLoaded && autoPopulatedAddress) || !addressLoaded)
      && (data.street === ''
        || data.street === null
        || typeof data.street === 'undefined');

    if (data.country !== 'NL') {
      disableAddress = false;
    }

    if (isLoading) {
      return <Loader className="FormLoader" />;
    }
    if (isSaved === true) {
      trackAddPaymentInfo();

      return (
        <Redirect
          to={`/${i18n.language}${generatePath(t('routes:ticket.overview'), {
            eventSlug,
          })}`}
        />
      );
    }

    return (
      <ValidatorForm
        ref={(node) => {
          this.form = node;
        }}
        onSubmit={this.handleSubmit}
        method="post"
      >
        <FormItem label={t('form.label.name')}>
          <Col md={5}>
            <Input
              placeholder={t('form.label.firstName')}
              validators={['required', 'isString']}
              errorMessages={[required, invalid]}
              name="firstName"
              onChange={this.handleChange}
              onBlur={this.handleFieldVisit}
              value={data.firstName}
              serverError={validation.firstName}
            />
          </Col>
          <Col md={2}>
            <Input
              placeholder={t('form.label.preposition.short')}
              // validators={['isString']}
              // errorMessages={[invalid]}
              name="preposition"
              onChange={this.handleChange}
              onBlur={this.handleFieldVisit}
              value={data.preposition}
            />
          </Col>
          <Col md={5}>
            <Input
              placeholder={t('form.label.lastName')}
              validators={['required', 'isString']}
              errorMessages={[required, invalid]}
              name="lastName"
              onChange={this.handleChange}
              onBlur={this.handleFieldVisit}
              value={data.lastName}
              serverError={validation.lastName}
            />
          </Col>
        </FormItem>
        <FormItem label={t('form.label.country')}>
          <Col md={6}>
            <RadioGroup
              options={[
                {
                  label: t('countries.nl'),
                  value: 'NL',
                },
                {
                  label: t('countries.other'),
                  value: 'Other',
                },
              ]}
              onChange={this.handleChange}
              onBlur={this.handleFieldVisit}
              value={data.country === 'NL' ? 'NL' : 'Other'}
              variant="horizontal"
              name="country"
            />
            <div
              className={classNames(
                'OtherCountry',
                data.country === 'NL' && 'Hidden',
              )}
            >
              <Dropdown
                options={translatedCountries}
                validators={['isSelected']}
                errorMessages={[required]}
                placeholder={t('form.label.country')}
                onChange={this.handleCountrySelectChange}
                onBlur={this.handleFieldVisit}
                value={
                  data.country != null
                    ? {
                      value: data.country,
                      label: t(`countries.${data.country.toLowerCase()}`),
                    }
                    : null
                }
                name="countryOther"
              />
            </div>
          </Col>
        </FormItem>
        <FormItem label={t('form.label.postcode')}>
          <Col md={4}>
            <Input
              placeholder={t('form.label.postcode')}
              validators={['required']}
              errorMessages={[required]}
              name="postcode"
              onChange={this.handlePostcodeHouseNumberChange}
              onBlur={this.handleFieldVisit}
              value={data.postcode}
              serverError={validation.postcode}
            />
          </Col>
        </FormItem>
        <FormItem label={t('form.label.houseNumberAndAddition')}>
          <Col md={4}>
            <Input
              placeholder={t('form.label.housenumber.short')}
              validators={['required', 'isNumber']}
              errorMessages={[required, invalid]}
              name="houseNumber"
              onChange={this.handlePostcodeHouseNumberChange}
              onBlur={this.handleFieldVisit}
              value={data.houseNumber}
              serverError={validation.houseNumber}
            />
          </Col>
          <Col md={3}>
            <Input
              placeholder={t('form.label.houseNumberAddition.short')}
              name="houseNumberAddition"
              onChange={this.handleChange}
              onBlur={this.handleFieldVisit}
              value={data.houseNumberAddition}
              serverError={validation.houseNumberAddition}
              autoComplete="nope"
            />
          </Col>
          {hideAddress && (
            <Col md={12} className="AutoPopulatedAddress">
              {data.street}
              {' '}
              {data.houseNumber}
              {data.houseNumberAddition && ` ${data.houseNumberAddition}`}
              ,
              {data.city}
              <Button variant="link" onClick={this.handleManualAddress}>
                {t('form.label.edit')}
              </Button>
            </Col>
          )}
        </FormItem>
        <FormItem label={t('form.label.address')} hidden={hideAddress}>
          <Col md={12}>
            <Input
              placeholder={t('form.label.address')}
              validators={['required']}
              errorMessages={[required]}
              name="street"
              onChange={this.handleChange}
              disabled={disableAddress}
              onBlur={this.handleFieldVisit}
              value={data.street}
              serverError={validation.street}
            />
          </Col>
        </FormItem>
        <FormItem label={t('form.label.city')} hidden={hideAddress}>
          <Col md={12}>
            <Input
              placeholder={t('form.label.city')}
              validators={['required']}
              errorMessages={[required]}
              name="city"
              onChange={this.handleChange}
              disabled={disableAddress}
              onBlur={this.handleFieldVisit}
              value={data.city}
              serverError={validation.city}
            />
          </Col>
        </FormItem>
        <FormItem label={t('form.label.email')}>
          <Col md={12}>
            <Input
              placeholder={t('form.label.email')}
              validators={['required', 'isEmail']}
              errorMessages={[required, invalid]}
              name="email"
              onChange={this.handleChange}
              onBlur={this.handleFieldVisit}
              value={data.email}
              serverError={validation.email}
            />
          </Col>
          <Col md={12}>
            <Checkbox
              label={t('form.label.newsletter')}
              name="newsletter"
              onChange={this.handleChange}
              onBlur={this.handleFieldVisit}
              value={data.newsletter}
            />
          </Col>
        </FormItem>
        <FormItem label={t('form.label.phoneNumber')}>
          <Col md={12}>
            <Input
              placeholder={t('form.label.phoneNumber')}
              validators={['required', 'matchRegexp:^[0-9\\+]*$']}
              errorMessages={[required, invalid]}
              name="phoneNumber"
              onChange={this.handleChange}
              onBlur={this.handleFieldVisit}
              value={data.phoneNumber}
              serverError={validation.phoneNumber}
            />
          </Col>
        </FormItem>
        <FormItem label={t('form.label.birthdate.long')}>
          <Col md={3}>
            <Input
              placeholder="DD-MM-YYYY"
              validators={['required', 'isDate']}
              errorMessages={[required, invalid]}
              name="birthdate"
              onChange={this.handleChange}
              onBlur={this.handleFieldVisit}
              value={data.birthdate}
              mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              serverError={validation.birthdate}
            />
          </Col>
        </FormItem>
        <FormItem label={t('form.label.church')}>
          <Col md={12}>
            <Input
              placeholder={t('form.label.church')}
              name="church"
              onChange={this.handleChange}
              onBlur={this.handleFieldVisit}
              value={data.church}
              serverError={validation.church}
            />
          </Col>
        </FormItem>

        <Row className="Buttons">
          <Col md={6}>
            {employee === false && (
              <LinkContainer
                to={`/${i18n.language}${generatePath(t('routes:ticket.cart'), {
                  eventSlug,
                })}`}
              >
                <Button className="BackLink BackLinkBottom" variant="link">
                  <ChevronLeftIcon />
                  {' '}
                  {t('button.back_to_cart')}
                </Button>
              </LinkContainer>
            )}
          </Col>
          <Col md={6}>
            <div className="ButtonRight">
              <Button variant="primary" disabled={submitDisabled} type="submit">
                {t('button.to_payment_screen')}
              </Button>
            </div>
          </Col>
        </Row>
      </ValidatorForm>
    );
  }
}

export default withTranslation()(DetailsForm);

DetailsForm.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  eventToken: PropTypes.string.isRequired,
  eventSlug: PropTypes.string.isRequired,
  employee: PropTypes.bool,
};