import React, {Component} from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {ValidatorForm} from 'react-form-validator-core';
import {withTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import PropTypes from 'prop-types';
import FormItem from 'components/Form/FormItem';
import PaymentOption from 'components/PaymentOption/index';
import Checkbox from 'components/Input/Checkbox';
import Loader from 'components/Loader';

import '../Form.scss';
import ToastContent from 'components/ToastContent';
import IdealForm from 'components/Form/Payment/IdealForm';
import CreditCardForm from 'components/Form/Payment/CreditCardForm';
import PaymentService from 'services/payment/PaymentService';

class PaymentForm extends Component {
  constructor(props) {
    super(props);

    let approveTermsAndConditions = false;
    if (props.terms === false) approveTermsAndConditions = true;

    this.state = {
      approveTermsAndConditions,
      selectedMethod: null,
      submitDisabled: true,
      isLoading: true,
      paymentMethods: [],
      isSaved: false,
      checkoutUrl: null,
      paymentService: new PaymentService(),
    };
  }

  componentDidMount() {
    this.waitUntilMollieExists().then(() => {
      this.loadPaymentMethods();
    });
  }

  waitUntilMollieExists = async () => {
    while (typeof Mollie === 'undefined') {
      // eslint-disable-next-line
      await new Promise((r) => setTimeout(r, 500));
    }
  };

  loadPaymentMethods = () => {
    const { t, country } = this.props;
    const { paymentService } = this.state;

    paymentService.getMethods(country, (methods) => {
      this.setState({
        paymentMethods: methods,
        isLoading: false,
      });
    }, () => {
      toast.error(<ToastContent type="error" message={t('error.payment.get')} />);
      this.setState({ isLoading: false });
    });
  };

  checkSubmitDisabledStatus = (approveTermAndConditions) => {
    const { paymentService } = this.state;

    if (!approveTermAndConditions) {
      return true;
    }

    return !paymentService.isValid();
  };

  handleTermsAndConditions = (event) => {
    this.setState({
      approveTermsAndConditions: event.target.value,
      submitDisabled: this.checkSubmitDisabledStatus(event.target.value),
    });
  };

  handlePayment = (orderSlug) => {
    const { t } = this.props;
    const { paymentService } = this.state;

    paymentService.handlePayment(orderSlug, (checkoutUrl) => {
      this.setState({
        isSaved: true,
        checkoutUrl,
      });
    }, () => {
      toast.error(<ToastContent type="error" message={t('error.payment.post')} />);
      this.setState({
        submitDisabled: false,
      });
    });
  };

  processOrder = () => {
    const {
      orderSlug, type, confirmOrder,
    } = this.props;

    if (type === 'first') {
      confirmOrder(this.handlePayment);
    } else {
      this.handlePayment(orderSlug);
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    window.onbeforeunload = null;

    const { submitDisabled, paymentService } = this.state;
    const { amount } = this.props;

    if (!this.form.isFormValid() || submitDisabled) {
      return false;
    }

    this.setState({
      submitDisabled: true,
    });

    paymentService.handleSubmit(amount, this.processOrder);

    return false;
  };

  handlePaymentMethodChange = (paymentMethod) => {
    const { paymentService, approveTermsAndConditions } = this.state;
    paymentService.setMethod(paymentMethod);

    this.setState({
      selectedMethod: paymentMethod,
      submitDisabled: this.checkSubmitDisabledStatus(approveTermsAndConditions),
    });
  };

  handlePaymentDetailsChange = (paymentDetails) => {
    const { paymentService, approveTermsAndConditions } = this.state;

    paymentService.setDetails(paymentDetails);

    this.setState({
      submitDisabled: this.checkSubmitDisabledStatus(approveTermsAndConditions),
    });
  };

  render() {
    const {
      children,
      terms = true,
      t,
    } = this.props;
    const {
      selectedMethod, isLoading, isSaved, checkoutUrl, approveTermsAndConditions,
      paymentService, paymentMethods, submitDisabled,
    } = this.state;

    let payText = t('button.pay.pay');

    if (selectedMethod !== null) {
      payText += ` ${t('button.pay.with')}`;

      if (selectedMethod === 'ideal') {
        payText += ` ${t('button.pay.ideal')}`;
      } else if (selectedMethod === 'creditcard') {
        payText += ` ${t('button.pay.creditcard')}`;
      } else if (selectedMethod === 'bancontact') {
        payText += ` ${t('button.pay.bancontact')}`;
      } else if (selectedMethod === 'applepay') {
        payText += ` ${t('button.pay.apple_pay')}`;
      }
    }

    if (isLoading) {
      return <Loader className="FormLoader" />;
    }

    if (isSaved === true && checkoutUrl !== null) {
      window.location.href = checkoutUrl;
      return <Loader className="FormLoader" />;
    }

    return (
      <ValidatorForm
        ref={(node) => {
          this.form = node;
        }}
        onSubmit={this.handleSubmit}
        method="post"
      >
        <div className="PaymentOptions">
          <PaymentOption
            type="ideal"
            show={paymentMethods.includes('ideal')}
            selected={selectedMethod === 'ideal'}
            className="IdealDropdown"
            handleChange={this.handlePaymentMethodChange}
            label={t('button.pay.ideal')}
          >
            <IdealForm paymentService={paymentService} onChange={this.handlePaymentDetailsChange} />
          </PaymentOption>

          {/* <PaymentOption */}
          {/*  type="applepay" */}
          {/*  show={paymentMethods.includes('applepay') */}
          {/* && window.ApplePaySession && ApplePaySession.canMakePayments()} */}
          {/*  selected={selectedMethod === 'applepay'} */}
          {/*  handleChange={this.handlePaymentMethodChange} */}
          {/*  label={t('button.pay.apple_pay')} */}
          {/* /> */}

          <PaymentOption
            type="bancontact"
            show={paymentMethods.includes('bancontact')}
            selected={selectedMethod === 'bancontact'}
            handleChange={this.handlePaymentMethodChange}
            label={t('button.pay.bancontact')}
          />

          <PaymentOption
            type="creditcard"
            show={paymentMethods.includes('creditcard')}
            selected={selectedMethod === 'creditcard'}
            handleChange={this.handlePaymentMethodChange}
            label={t('button.pay.creditcard')}
          >
            <CreditCardForm
              paymentService={paymentService}
              onChange={this.handlePaymentDetailsChange}
            />
          </PaymentOption>
        </div>

        {terms && (
          <FormItem>
            <Col md={12}>
              <Checkbox
                label={`${t('form.label.approveTermsAndConditions.iAgree')} `
                + `<a href="${t('termsAndPrivacy.termsAndConditions.url')}" target="_blank" rel="noopener noreferrer">${
                  t('termsAndPrivacy.termsAndConditions.label')
                }</a>`}
                name="approveTermsAndConditions"
                onChange={this.handleTermsAndConditions}
                value={approveTermsAndConditions}
              />
            </Col>
          </FormItem>
        )}

        <Row className="Buttons">
          <Col md={6}>
            {children}
          </Col>
          <Col md={6}>
            <div className="ButtonRight">
              <Button variant="success" disabled={submitDisabled} onClick={this.handleSubmit}>
                {payText}
              </Button>
            </div>
          </Col>
        </Row>
      </ValidatorForm>
    );
  }
}

export default withTranslation()(PaymentForm);

PaymentForm.propTypes = {
  t: PropTypes.func.isRequired,
  orderSlug: PropTypes.string,
  terms: PropTypes.bool,
  confirmOrder: PropTypes.func,
  children: PropTypes.node,
  type: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  amount: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired,
  }).isRequired,
};