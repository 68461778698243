import React, {Component} from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {ValidatorForm} from 'react-form-validator-core';
import {withTranslation} from 'react-i18next';
import './Form.scss';
import {generatePath, Redirect} from 'react-router-dom';
import {Alert} from 'react-bootstrap';
import {toast} from 'react-toastify';
import PropTypes from 'prop-types';
import FormItem from 'components/Form/FormItem';
import Input from 'components/Input/index';
import ToastContent from 'components/ToastContent';
import Checkbox from 'components/Input/Checkbox';
import {logError} from 'services/error-handling/ErrorHandler';
import {handleInternalApiCall, METHOD_POST} from 'services/api-requests/ApiCallHandler';
import LocalStorageService from 'services/storage/LocalStorageService';

class YouthLeaderForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        churchName: '',
        churchEmail: '',
        numberOfYouth: '',
        numberOfYouthLeaders: '',
        isYouthLeader: false,
        approveTermsAndConditions: false,
      },
      visitedFields: {},
      changedFieldsSinceFormSubmission: {},
      validation: {},
      isSaved: false,
      formError: '',
      generalError: '',
      submitDisabled: true,
      orderToken: '',
    };

    window.onbeforeunload = function () {
      return true;
    };
  }

  handleTermsConditionsChange = (event) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        approveTermsAndConditions: event.target.value,
      },
      submitDisabled: !event.target.value,
    });
  };

  handleChange = (event, callback = (() => {
  })) => {
    const { data, changedFieldsSinceFormSubmission } = this.state;
    this.setState({
      data: {
        ...data,
        [event.target.name]: event.target.value,
      },
      changedFieldsSinceFormSubmission: {
        ...changedFieldsSinceFormSubmission,
        [event.target.name]: true,
      },
      validation: {
        [event.target.name]: false,
      },
    }, callback);
  };

  handleFieldVisit = (event) => {
    const { visitedFields } = this.state;
    this.setState({
      visitedFields: {
        ...visitedFields,
        [event.target.name]: true,
      },
    });
  };

  handleSubmitted = (orderToken) => {
    this.setState({
      isSaved: true,
      orderToken,
    });
  };

  handleSubmit = (event) => {
    const { eventToken, confirmOrder, t } = this.props;
    const { data } = this.state;
    event.preventDefault();

    window.onbeforeunload = null;
    this.setState({
      submitDisabled: true,
    });
    if (this.form.isFormValid()) {
      handleInternalApiCall(`/shop/cart/${LocalStorageService.getCartUuid()}/event/${eventToken}/youth-leader/application`,
        METHOD_POST,
        false,
        {
          churchName: data.churchName,
          churchEmail: data.churchEmail,
          numberOfYouth: parseInt(data.numberOfYouth, 10),
          numberOfYouthLeaders: parseInt(data.numberOfYouthLeaders, 10),
        },
        () => {
          confirmOrder(this.handleSubmitted);
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            let errorMessage = '';
            let validation = {};

            if (error.response.data.fields !== undefined) {
              validation = error.response.data.fields;
            }
            if (error.response.data.message !== undefined) {
              errorMessage = error.response.data.message;
            }

            this.setState({
              validation,
              formError: errorMessage,
            });
          } else {
            logError(error);
            toast.error(<ToastContent
              type="error"
              message={t('error.youthleader.post')}
            />);
          }
          this.setState({
            submitDisabled: false,
          });
        });
    }

    return false;
  };

  render() {
    const {
      children,
      onUpdate,
      i18n,
      t,
    } = this.props;
    const {
      generalError, orderToken, isSaved, data, formError, submitDisabled, validation,
    } = this.state;

    const required = t('form.validation.required');
    const invalid = t('form.validation.invalid');

    if (generalError !== '') {
      return <Alert variant="danger">{generalError}</Alert>;
    }

    if (isSaved) {
      return (
        <Redirect
          to={`/${i18n.language}${generatePath(t('routes:ticket.order_youthleader'),
            { orderSlug: orderToken })}`}
        />
      );
    }

    return (
      <ValidatorForm
        ref={(node) => { this.form = node; }}
        onSubmit={this.handleSubmit}
        method="post"
      >
        <FormItem>
          <Col md={12}>
            <Checkbox
              label={t('form.label.isYouthLeader')}
              name="isYouthLeader"
              onChange={(event) => {
                this.handleChange(event);
                onUpdate(!event.target.value);
              }}
              onBlur={this.handleFieldVisit}
              value={data.isYouthLeader}
            />
          </Col>
        </FormItem>
        {data.isYouthLeader && (
          <div>
            <FormItem label={t('form.label.church')}>
              <Col md={12}>
                <Input
                  placeholder={t('form.label.church')}
                  validators={['required', 'isString']}
                  errorMessages={[required, invalid]}
                  name="churchName"
                  onChange={this.handleChange}
                  onBlur={this.handleFieldVisit}
                  value={data.churchName}
                  serverError={validation.churchName}
                />
              </Col>
            </FormItem>
            <FormItem label={t('form.label.churchEmail')}>
              <Col md={12}>
                <Input
                  placeholder={t('form.label.churchEmail')}
                  validators={['required', 'isEmail']}
                  errorMessages={[required, invalid]}
                  name="churchEmail"
                  onChange={this.handleChange}
                  onBlur={this.handleFieldVisit}
                  value={data.churchEmail}
                  serverError={validation.churchEmail}
                />
              </Col>
            </FormItem>
            <Row>
              <Col md={4}>
                <FormItem label={t('form.label.numberOfYouthLeaders')}>
                  <Input
                    placeholder={t('form.label.numberOfYouthLeaders')}
                    validators={['required', 'isNumber', 'isPositive']}
                    errorMessages={[required, invalid, invalid]}
                    name="numberOfYouthLeaders"
                    onChange={this.handleChange}
                    onBlur={this.handleFieldVisit}
                    value={data.numberOfYouthLeaders}
                    serverError={validation.numberOfYouthLeaders}
                  />
                </FormItem>
              </Col>
              <Col md={4}>
                <FormItem label={t('form.label.numberOfYouth')}>
                  <Input
                    placeholder={t('form.label.numberOfYouth')}
                    validators={['required', 'isNumber', 'isPositive']}
                    errorMessages={[required, invalid, invalid]}
                    name="numberOfYouth"
                    onChange={this.handleChange}
                    onBlur={this.handleFieldVisit}
                    value={data.numberOfYouth}
                    serverError={validation.numberOfYouth}
                  />
                </FormItem>
              </Col>
            </Row>
            <FormItem>
              <Col md={12}>
                <Checkbox
                  label={`${t('form.label.approveTermsAndConditions.iAgree')} `
                  + `<a href="${t('termsAndPrivacy.termsAndConditions.url')}" target="_blank" rel="noopener noreferrer">${
                    t('termsAndPrivacy.termsAndConditions.label')
                  }</a>`}
                  name="approveTermsAndConditions"
                  onChange={this.handleTermsConditionsChange}
                  value={data.approveTermsAndConditions}
                />
              </Col>
            </FormItem>

            {formError !== '' && <Alert variant="danger">{formError}</Alert>}

            <Row className="Buttons">
              <Col md={6}>
                {children}
              </Col>
              <Col md={6}>
                <div className="ButtonRight">
                  <Button variant="success" disabled={submitDisabled} type="submit">
                    {t('button.youthleader')}
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </ValidatorForm>
    );
  }
}

export default withTranslation()(YouthLeaderForm);

YouthLeaderForm.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  children: PropTypes.node.isRequired,
  onUpdate: PropTypes.func.isRequired,
  eventToken: PropTypes.string.isRequired,
  confirmOrder: PropTypes.func.isRequired,
};
