import {GetCartQueryParams, useGetCart} from "./useGetCart";

interface useGetAmountProps {
    eventToken: string;
    token: string;
}

export const useGetAmount = ({
                                    eventToken,
                                    token,
                                }: useGetAmountProps) => {

    const {
        data: data,
        isLoading: isCartLoading,
        isError: isCartError
    } = useGetCart({eventToken} as GetCartQueryParams);

    const products = data?.products ?? [];

    const item = products.find((item => item.token === token));

    return item ? item.quantity : 0;
};