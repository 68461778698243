import React from 'react';
import Header from "../../components/Header";
import DocumentTitle from "../../components/DocumentTitle";
import Footer from "../../components/Footer";
import {Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";

type ErrorPageProps = {
    title: string,
    text: string,
}

export const ErrorPage: React.FC<ErrorPageProps> = ({title, text}) => {
    const { t } = useTranslation();

    return <div className="Error">
        <Header />

        <div className="Main">
            <Container>
                <Row>
                    <Col md={{ span: 6, offset: 3 }}>
                        <DocumentTitle title="Soul Survivor | Tickets"/>

                        <h2>{title}</h2>
                        <p>
                            {text}
                        </p>
                </Col>
            </Row>

            </Container>
        </div>

        <Footer/>
    </div>;
}

export default ErrorPage;