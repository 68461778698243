/* eslint react/forbid-prop-types: 0 */
import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import {withTranslation} from 'react-i18next';
import './Header.scss';
import {LinkContainer} from 'react-router-bootstrap';
import {generatePath} from 'react-router';
import {NavDropdown} from 'react-bootstrap';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined';
import PropTypes from 'prop-types';
import * as sessionActions from 'actions/sessionActions';
import LocalStorageService from 'services/storage/LocalStorageService';
import Logo from 'components/Logo/index';
import {classNames} from '../../util';

function getKeyByValue(object, value) {
  const result = Object.keys(object).find((key) => object[key] === value);

  if (result) {
    return `ticket.${result}`;
  }

  return false;
}

const Header = ({
  route, loggedIn, actions, t, i18n,
}) => {
  const selected = i18n.language;
  const user = LocalStorageService.getUser();
  const fullName = user.preposition
    ? `${user.firstName} ${user.preposition} ${user.lastName}`
    : `${user.firstName} ${user.lastName}`;

  const languages = [
    { code: 'nl', name: 'Nederlands' },
    { code: 'en', name: 'English' },
  ];
  return (
    <Navbar className="Header" variant="light" fixed="top">
      {route ? (
        <Container>
          <LinkContainer to={`/${selected}`}>
            <Navbar.Brand className="Logo">
              <Logo />
            </Navbar.Brand>
          </LinkContainer>
          <Nav className="Navigation">
            <NavDropdown
              title={selected.toUpperCase()}
              id="basic-nav-dropdown"
              className="LanguagePicker"
            >
              {languages.map((language) => {
                const resources = i18n.getResourceBundle(selected, 'routes');
                const routeKey = route.path.substring(3);
                const translatedPath = `/${language.code}${t(
                  `routes:${getKeyByValue(resources.ticket, routeKey)}`,
                  { lng: language.code },
                )}`;
                const url = generatePath(translatedPath, route.params);

                if (language.code !== selected) {
                  return (
                    <NavDropdown.Item
                      key={language.code}
                      href={url}
                      className={classNames('LanguagePickerItem')}
                      title={language.name}
                    >
                      {language.name}
                    </NavDropdown.Item>
                  );
                }
                return null;
              })}
            </NavDropdown>
            {loggedIn ? (
              <NavDropdown title={fullName} alignRight>
                <LinkContainer
                  to={`/${i18n.language}${generatePath(
                    t('routes:ticket.account_orders'),
                  )}`}
                >
                  <NavDropdown.Item>
                    <LocalMallOutlinedIcon className="MenuIcon" />
                    {t('menu.orders')}
                  </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer
                  to={`/${i18n.language}${generatePath(
                    t('routes:ticket.account_tickets'),
                  )}`}
                >
                  <NavDropdown.Item>
                    <ConfirmationNumberOutlinedIcon className="MenuIcon" />
                    {t('menu.tickets')}
                  </NavDropdown.Item>
                </LinkContainer>
                <NavDropdown.Divider />
                <LinkContainer
                  to={`/${i18n.language}${generatePath(
                    t('routes:ticket.account_change_password'),
                  )}`}
                >
                  <NavDropdown.Item>
                    <VpnKeyOutlinedIcon className="MenuIcon" />
                    {t('menu.change_password')}
                  </NavDropdown.Item>
                </LinkContainer>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={actions.logout}>
                  {t('menu.logout')}
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <LinkContainer
                to={`/${i18n.language}${generatePath(
                  t('routes:ticket.account_login'),
                )}`}
              >
                <Nav.Link>{t('menu.my_account')}</Nav.Link>
              </LinkContainer>
            )}
          </Nav>
        </Container>
      ) : (
        <Container>
          <a href="/">
            <Navbar.Brand className="Logo">
              <Logo />
            </Navbar.Brand>
          </a>
        </Container>
      )}
    </Navbar>
  );
};

function mapStateToProps(state) {
  return { loggedIn: state.session };
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(sessionActions, dispatch),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Header),
);

Header.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
    getResourceBundle: PropTypes.func,
  }).isRequired,
  loggedIn: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    logout: PropTypes.func.isRequired,
  }).isRequired,
  route: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.any,
  }),
};