import React, {useEffect, useState} from 'react';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import MinusIcon from '@material-ui/icons/Remove';
import PlusIcon from '@material-ui/icons/Add';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Moment from 'react-moment';
import MoneyString from 'components/MoneyString';
import {classNames} from '../../util';
import {useTranslation} from 'react-i18next';
import {Amount} from "../../types/Amount.type";
import {Discount} from "../../types/Discount.type";
import {useChangeCartItem} from "../../hooks/Cart/useChangeCartItem";
import {useGetAmount} from "../../hooks/Cart/useGetAmount";

interface Props {
    openByDefault: boolean;
    eventToken: string;
    token: string;
    name: string;
    description: string;
    priceIncludingVat: Amount;
    discountedPriceIncludingVat?: Amount
    discounts?: Discount[];
}

const EventTicketVariety: React.FC<Props> = ({
                                                 openByDefault,
                                                 eventToken,
                                                 token,
                                                 name,
                                                 description,
                                                 priceIncludingVat,
                                                 discountedPriceIncludingVat,
                                                 discounts = [],
                                             }) => {
    const initialAmount = useGetAmount({eventToken, token});

    const [amount, setAmount] = useState(initialAmount);
    const [open, setOpen] = useState(openByDefault || initialAmount > 0);
    const {t, i18n} = useTranslation();

    const handleToggle = () => setOpen((prev) => !prev);

    const {changeAmount} = useChangeCartItem({
        eventToken,
        errorMessage: t('error.cart.edit')
    });

    useEffect(() => {
        const handler = setTimeout(() => {
            if (amount !== initialAmount) {
                changeAmount(token, amount);
            }
        }, 300);

        return () => clearTimeout(handler);
    }, [amount, changeAmount, initialAmount]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newAmount = parseInt(e.target.value, 10);
        if (!isNaN(newAmount)) {
            setAmount(newAmount);
        }
    };

    const handleMinus = () => {
        setAmount(Math.max(0, amount - 1));
    }
    const handlePlus = () => {
        setAmount(amount + 1);
    }

    const price = discountedPriceIncludingVat || priceIncludingVat;

    const activeDiscounts = discounts.filter(
        (discount) =>
            discount.active && ['EARLY_BIRD_DISCOUNT', 'EMPLOYEE_DISCOUNT'].includes(discount.type)
    );
    const firstDiscount = activeDiscounts[0];

    return (
        <div className={classNames('EventTicketVariety', open && 'Opened')} id={token}>
            <div
                className="EventTicketVarietyHeader"
                onClick={handleToggle}
                role="button"
                tabIndex={0}
            >
                <div className="EventTicketVarietyName">
                    <h3>{name}</h3>
                    {discountedPriceIncludingVat && firstDiscount && (
                        <div className="DiscountBadge">
                            {t(`cart.discountTypes.${firstDiscount.type.toLowerCase()}`)}
                        </div>
                    )}
                </div>
                <div className={open ? 'EventTicketVarietyDropDown Show' : 'EventTicketVarietyDropDown Hidden'}>
                    <ArrowDropDownCircleIcon className="EventTicketVarietyShowHide"/>
                </div>
                <div className="EventTicketVarietyHeaderPrice">
                    <MoneyString amount={price.amount} currency={price.currency}/>
                </div>
            </div>
            {open && (
                <div className="EventTicketVarietyBody">
                    <div className="EventTicketVarietyDescription">
                        {description &&
                            description.split('\n').map((item, index) => (
                                <span key={index}>
                  {item}
                                    <br/>
                </span>
                            ))}
                    </div>
                    <Row className="EventTicketVarietyFooter">
                        <Col className="EventTicketVarietyPrice" md={6}>
                            <span className="EventTicketVarietyPriceCurrent">
                                <MoneyString amount={price.amount} currency={price.currency}/>
                          </span>
                            {discountedPriceIncludingVat && (
                                <span className="EventTicketVarietyPriceNext">
                                  <MoneyString
                                      amount={priceIncludingVat.amount}
                                      currency={priceIncludingVat.currency}
                                  />
                                </span>
                            )}
                            {discountedPriceIncludingVat && firstDiscount && (
                                <div className="EventTicketVarietyPriceTill">
                                    {t('event.discount_till')}{' '}
                                    <Moment
                                        date={firstDiscount.validTill}
                                        locale={i18n.language}
                                        format="D MMM YYYY"
                                    />
                                </div>
                            )}
                        </Col>
                        <Col className="EventTicketVarietyAmount" md={6}>
                            <Button className="TicketButton TicketMinus" disabled={amount === 0} onClick={handleMinus}>
                                <MinusIcon/>
                            </Button>
                            <Form.Control
                                className="TicketAmount"
                                type="text"
                                value={amount}
                                onChange={handleChange}
                            />
                            <Button
                                className="TicketButton TicketPlus"
                                disabled={amount >= 99}
                                onClick={handlePlus}
                            >
                                <PlusIcon/>
                            </Button>
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    );
};

export default EventTicketVariety;