import {useQuery} from "@tanstack/react-query";
import {SingleEvent} from "../../types/SingleEvent.type";
import {handleInternalApiCall, METHOD_GET} from "../../services/api-requests/ApiCallHandler";
import {SingleTicket} from "../../types/SingleTicket.type";

export type GetEventTicketsQueryParams = {
    eventSlug: string;
}

const QUERY_KEY = 'eventTickets';

const fetchEventTickets = async (params: GetEventTicketsQueryParams): Promise<{
    event: SingleEvent,
    tickets: SingleTicket[],
}> => {
    const response = await handleInternalApiCall(
        `shop/event/${params.eventSlug}/tickets`,
        METHOD_GET,
        false,
        null
    );

    return {
        event: response.data.event,
        tickets: response.data.tickets
    };
};

export const useGetEventTickets = (params: GetEventTicketsQueryParams) => {
    return useQuery<{
        event: SingleEvent,
        tickets: SingleTicket[],
    }, Error>(useGetEventTicketsQuery(params));
};

export const useGetEventTicketsQuery = (params: GetEventTicketsQueryParams) => {
    return {
        queryKey: [QUERY_KEY + params.eventSlug],
        queryFn: () => fetchEventTickets(params)
    }
};