import React, {Component} from 'react';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';

import './Checkbox.scss';
import './Input.scss';

export default class Checkbox extends Component {
  render() {
    const {
      serverError,
      label,
      value = false,
      onChange,
      onBlur,
      required,
      name,
    } = this.props;

    return (
      <Form.Group className="CheckboxGroup">
        <label className="Checkbox">
          <input
            type="checkbox"
            checked={value}
            required={required}
            name={name}
            onChange={() => onChange({ target: { name, value: !value } })}
            onBlur={onBlur ? () => onBlur({ target: { name, value: !value } }) : undefined}
            ref={(r) => {
              this.input = r;
            }}
          />
          <div className="icon" />
          <span dangerouslySetInnerHTML={{ __html: label }} />
        </label>
        {serverError && serverError.map(
          (errorText, index) => (// eslint-disable-next-line react/no-array-index-key
            <Form.Text key={index} className="InputError">
              {errorText}
            </Form.Text>
          ),
        )}
      </Form.Group>
    );
  }
}

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  serverError: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.bool,
  ]),
  required: PropTypes.bool,
};