import React from 'react';
import PropTypes from 'prop-types';
import {
    LogoAmericanExpress,
    LogoApplePay,
    LogoBancontact,
    LogoIdeal,
    LogoMasterCard,
    LogoVisa,
} from 'components/Logo/index';

import './PaymentOption.scss';
import {classNames} from '../../util';

const paymentLogos = {
  applepay: [LogoApplePay],
  ideal: [LogoIdeal],
  bancontact: [LogoBancontact],
  creditcard: [
    LogoAmericanExpress,
    LogoVisa,
    LogoMasterCard,
  ],
};

const PaymentOption = ({
  type, children, selected, handleChange, show, label,
}) => {
  if (!show) {
    return null;
  }

  const selectFunction = () => { handleChange(type); };

  const keyDownFunction = (event) => {
    if (event.key === 'Enter') {
      selectFunction();
    }
  };

  return (
    <div className={classNames('PaymentOption', selected && 'Selected')}>
      { /* eslint-disable-next-line */ }
      <div className="PaymentOptionHeader" onClick={selectFunction} onKeyDown={keyDownFunction}>
        <div className="PaymentOptionSelect">
          <div className="PaymentOptionRadio">
            <div className="icon" />
          </div>
        </div>
        <div className="PaymentOptionName">{label}</div>
        {paymentLogos[type].map((LogoPaymentOption, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="PaymentOptionLogo" key={`${type}-${index}`}>
            <LogoPaymentOption />
          </div>
        ))}
      </div>

      {children && (
      <div className="PaymentOptionBody">
        <div className="PaymentOptionBodyContents">{children}</div>
      </div>
      )}
    </div>
  );
};

export default PaymentOption;

PaymentOption.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.node,
  selected: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  show: PropTypes.bool,
  label: PropTypes.string.isRequired,
};