import React, {Component} from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {ValidatorForm} from 'react-form-validator-core';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {toast} from 'react-toastify';
import Input from 'components/Input/index';
import FormItem from 'components/Form/FormItem';
import './Form.scss';
import {handleApiCall, METHOD_POST} from 'services/api-requests/ApiCallHandler';
import ToastContent from 'components/ToastContent';
import {logError} from 'services/error-handling/ErrorHandler';
import LocalStorageService from 'services/storage/LocalStorageService';

class ChangePasswordForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        currentPassword: '',
        newPassword: '',
        isSaved: false,
      },
      validation: {},
    };
  }

  handleChange = (event, callback = (() => {
  })) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [event.target.name]: event.target.value,
      },
      validation: {
        [event.target.name]: false,
      },
    }, callback);
  };

  handleSubmit = (event) => {
    const { data } = this.state;
    const { t } = this.props;
    const user = LocalStorageService.getUser();

    event.preventDefault();

    this.setState({
      submitDisabled: true,
    });

    if (this.form.isFormValid()) {
      const currentPassword = user.hasPassword ? data.currentPassword : null;
      handleApiCall('/me/change-password', METHOD_POST, true, {
        currentPassword,
        newPassword: data.newPassword,
      },
      () => {
        this.setState({
          isSaved: true,
        });
        LocalStorageService.handlePasswordChange();
      },
      (error) => {
        if (error.response && error.response.status === 400) {
          if (error.response.data.fields !== undefined) {
            this.setState({
              validation: error.response.data.fields,
            });
          } else if (error.response.data.message !== undefined) {
            logError(error);
            toast.error(<ToastContent type="error" message={error.response.data.message} />);
          }
        } else {
          logError(error);
          toast.error(<ToastContent type="error" message={t('error.password.post')} />);
        }
        this.setState({
          submitDisabled: false,
        });
      });
    }

    return false;
  };

  render() {
    const { t } = this.props;
    const user = LocalStorageService.getUser();

    const {
      data, submitDisabled, validation, isSaved,
    } = this.state;

    const required = t('form.validation.required');

    return (
      <ValidatorForm
        ref={(node) => {
          this.form = node;
        }}
        onSubmit={this.handleSubmit}
        method="post"
      >
        {isSaved ? (
          <div>
            {t('account.password_changed')}
          </div>
        ) : (
          <div>
            {user.hasPassword && (
            <FormItem label={t('form.label.password_current')}>
              <Col md={12}>
                <Input
                  placeholder={t('form.label.password_current')}
                  validators={['required']}
                  errorMessages={[required]}
                  name="currentPassword"
                  onChange={this.handleChange}
                  value={data.currentPassword}
                  serverError={validation.currentPassword}
                  type="password"
                />
              </Col>
            </FormItem>
            )}
            <FormItem label={t('form.label.password_new')}>
              <Col md={12}>
                <Input
                  placeholder={t('form.label.password_new')}
                  validators={['required']}
                  errorMessages={[required]}
                  name="newPassword"
                  onChange={this.handleChange}
                  value={data.newPassword}
                  serverError={validation.newPassword}
                  type="password"
                />
              </Col>
            </FormItem>

            <Row className="Buttons">
              <Col md={6} />
              <Col md={6}>
                <div className="ButtonRight">
                  <Button variant="primary" disabled={submitDisabled} type="submit">
                    {t('button.change_password')}
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </ValidatorForm>
    );
  }
}

export default withTranslation()(ChangePasswordForm);

ChangePasswordForm.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
};
