import React, {Component} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import {withTranslation} from 'react-i18next';
import Moment from 'react-moment';
import './TicketItem.scss';
import {toast} from 'react-toastify';
import GetAppIcon from '@material-ui/icons/GetApp';
import {saveAs} from 'file-saver';
import Spinner from 'react-bootstrap/Spinner';
import PropTypes from 'prop-types';
import ToastContent from 'components/ToastContent';
import {logError} from 'services/error-handling/ErrorHandler';
import {handleLegacyInternalApiCall, METHOD_GET} from 'services/api-requests/ApiCallHandler';

class TicketItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadDisabled: false,
    };
  }

  getTicketPDF = () => {
    const { ticketToken, eventName, t } = this.props;
    this.setState({
      downloadDisabled: true,
    });

    handleLegacyInternalApiCall(`personalize/ticket/${ticketToken}/pdf`,
      METHOD_GET,
      true,
      null,
      (response) => {
        const fileName = `ticket-${eventName}-${ticketToken}.pdf`;
        saveAs(new Blob([response.data], { type: 'application/pdf;charset=utf-8' }), fileName);

        this.setState({
          downloadDisabled: false,
        });
      },
      (error) => {
        logError(error);
        toast.error(<ToastContent type="error" message={t('error.ticket.pdf')} />);
        this.setState({
          downloadDisabled: false,
        });
      },
      { responseType: 'blob' });
  };

  render() {
    const {
      ticketName, eventName, ticketNumber, fromDate, tillDate, i18n, t,
    } = this.props;
    const { downloadDisabled } = this.state;

    return (
      <Row className="TicketItem">
        <Col className="TicketItemEvent" md={3} sm={12}>
          <div className="TicketItemName">{eventName}</div>
          <div className="TicketItemEventDate">
            <Moment date={fromDate} locale={i18n.language} format="D MMM YYYY" />
            {fromDate !== tillDate && (
              <span>
&nbsp;
                {t('event.date_till')}
                {' '}
                <Moment date={tillDate} locale={i18n.language} format="D MMM YYYY" />
              </span>
            )}
          </div>
        </Col>
        <Col className="TicketItemToken" md={2} sm={12}>
          {ticketNumber}
        </Col>
        <Col className="TicketItemName" md={4} sm={12}>
          {ticketName}
        </Col>
        <Col className="TicketItemButton" md={3} sm={12}>
          <Button
            variant="link"
            className="TicketItemPersonalizeButton"
            disabled={downloadDisabled}
            onClick={this.getTicketPDF}
          >
            {downloadDisabled ? (
              <span>
                {t('button.download_ticket')}
                <span className="TicketLoader">
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </span>
              </span>
            ) : (
              <span>
                {t('button.download_ticket')}
                <GetAppIcon className="TicketItemPersonalizeButtonIcon" />
              </span>
            )}
          </Button>
        </Col>
      </Row>
    );
  }
}

export default withTranslation()(TicketItem);

TicketItem.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  eventName: PropTypes.string.isRequired,
  ticketName: PropTypes.string.isRequired,
  ticketNumber: PropTypes.string.isRequired,
  ticketToken: PropTypes.string.isRequired,
  fromDate: PropTypes.string.isRequired,
  tillDate: PropTypes.string.isRequired,
};
