import React, {Component} from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {ValidatorForm} from 'react-form-validator-core';
import {withTranslation} from 'react-i18next';
import moment from 'moment';
import {Alert} from 'react-bootstrap';
import {toast} from 'react-toastify';
import PropTypes from 'prop-types';
import Input from 'components/Input/index';
import FormItem from 'components/Form/FormItem';
import RadioGroup from 'components/RadioGroup/index';
import Loader from 'components/Loader';
import './Form.scss';
import Dropdown from 'components/Input/Dropdown';
import ToastContent from 'components/ToastContent';
import {logError} from 'services/error-handling/ErrorHandler';
import {handleLegacyInternalApiCall, METHOD_GET, METHOD_POST,} from 'services/api-requests/ApiCallHandler';

class VolunteerForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        firstName: '',
        preposition: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        dateOfBirth: '',
        lastYearActiveAsEmployee: '',
        motivation: '',
        firstPreferredWorkgroup: '',
        secondPreferredWorkgroup: '',
        thirdPreferredWorkgroup: '',
        previouslyActiveAsEmployee: false,
      },
      selection: {
        firstPreferredWorkgroup: null,
        secondPreferredWorkgroup: null,
        thirdPreferredWorkgroup: null,
      },
      visitedFields: {},
      changedFieldsSinceFormSubmission: {},
      validation: {},
      isSaved: false,
      workgroups: [],
      isLoading: true,
      formError: '',
      generalError: '',
    };

    window.onbeforeunload = function () {
      return true;
    };
  }

  componentDidMount() {
    ValidatorForm.addValidationRule('isDate', (value) => {
      const regex = /^([0-2][0-9]|(3)[0-1])(-)(((0)[0-9])|((1)[0-2]))(-)\d{4}$/i;
      return regex.test(value);
    });

    ValidatorForm.addValidationRule('isSelected', (value) => {
      if (value === undefined) return false;
      if (value === null) return false;

      return (value.value !== '' && value.value !== null);
    });

    this.getWorkgroups();
  }

  handleWorkgroupSelectChange = (name, event) => {
    let { value } = event;
    if (value === '0') {
      value = '';
    }

    this.handleChange({
      target: {
        name,
        value,
      },
    });

    const { selection } = this.state;

    this.setState({
      selection: {
        ...selection,
        [name]: event,
      },
    });
  };

  handleChange = (event, callback = (() => {
  })) => {
    const { data, changedFieldsSinceFormSubmission } = this.state;
    this.setState({
      data: {
        ...data,
        [event.target.name]: event.target.value,
      },
      changedFieldsSinceFormSubmission: {
        ...changedFieldsSinceFormSubmission,
        [event.target.name]: true,
      },
      validation: {
        [event.target.name]: false,
      },
    }, callback);
  };

  handlePreviouslyActive = (event) => {
    this.handleChange(event, () => {
      if (event.target.value === false) {
        const { data } = this.state;
        this.setState({
          data: {
            ...data,
            lastYearActiveAsEmployee: '',
          },
        });
      }
    });
  };

  handleFieldVisit = (event) => {
    const { visitedFields } = this.state;
    this.setState({
      visitedFields: {
        ...visitedFields,
        [event.target.name]: true,
      },
    });
  };

  getDisabledStatus = (preference, value) => {
    const { data } = this.state;
    if (value === '0') return false;

    if (preference === 1) {
      return value === data.secondPreferredWorkgroup || value === data.thirdPreferredWorkgroup;
    }
    if (preference === 2) {
      return value === data.firstPreferredWorkgroup || value === data.thirdPreferredWorkgroup;
    }
    if (preference === 3) {
      return value === data.firstPreferredWorkgroup || value === data.secondPreferredWorkgroup;
    }
    return false;
  };

  handleSubmit = (event) => {
    const { t, eventToken } = this.props;
    const { data } = this.state;
    event.preventDefault();

    window.onbeforeunload = null;
    this.setState({
      submitDisabled: true,
    });
    if (this.form.isFormValid()) {
      const dateOfBirth = moment(data.dateOfBirth, 'DD-MM-YYYY');

      handleLegacyInternalApiCall(`event/${eventToken}/job-application/create`,
        METHOD_POST,
        false,
        {
          firstName: data.firstName,
          preposition: data.preposition,
          lastName: data.lastName,
          phoneNumber: data.phoneNumber,
          email: data.email,
          dateOfBirth: dateOfBirth.format('YYYY-MM-DD'),
          lastYearActiveAsEmployee: parseInt(data.lastYearActiveAsEmployee, 10),
          motivation: data.motivation,
          firstPreferredWorkgroup: data.firstPreferredWorkgroup,
          secondPreferredWorkgroup: data.secondPreferredWorkgroup,
          thirdPreferredWorkgroup: data.thirdPreferredWorkgroup,
        },
        () => {
          this.setState({
            isSaved: true,
          });
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            let errorMessage = '';
            let validation = {};

            if (error.response.data.fields !== undefined) {
              validation = error.response.data.fields;
            }
            if (error.response.data.message !== undefined) {
              errorMessage = error.response.data.message;
            }

            this.setState({
              validation,
              formError: errorMessage,
            });
          } else {
            logError(error);
            toast.error(<ToastContent
              type="error"
              message={t('error.volunteer.post')}
            />);
          }
          this.setState({
            submitDisabled: false,
          });
        });
    }

    return false;
  };

  getWorkgroups = () => {
    const { t, eventToken } = this.props;
    handleLegacyInternalApiCall(`event/${eventToken}/workgroup`,
      METHOD_GET,
      false,
      null,
      (response) => {
        const workgroups = response.data.map((team) => {
          const options = team.workgroups.map((workgroup) => ({
            label: workgroup.name,
            value: workgroup.token,
          }));

          return { label: team.name, options };
        });

        workgroups.unshift({ options: [{ label: t('volunteer.no_preference'), value: '0' }] });

        this.setState({
          workgroups,
          isLoading: false,
        });
      },
      (error) => {
        if (error.response && error.response.status === 400) {
          if (error.response.data.message !== undefined) {
            this.setState({
              generalError: error.response.data.message,
            });
          }
        } else {
          logError(error);
          toast.error(<ToastContent type="error" message={t('error.volunteer.get')} />);
        }
        this.setState({ isLoading: false });
      });
  };

  render() {
    const {
      t,
    } = this.props;
    const {
      isSaved, data, isLoading, formError, generalError, selection, validation,
      workgroups, submitDisabled,
    } = this.state;

    const required = t('form.validation.required');
    const invalid = t('form.validation.invalid');

    if (isSaved === true) {
      return (
        <p>
          {t('volunteer.thanks')}
        </p>
      );
    }

    if (isLoading) {
      return <Loader className="FormLoader" />;
    }

    if (generalError !== '') {
      return <Alert variant="danger">{generalError}</Alert>;
    }

    return (
      <ValidatorForm
        ref={(node) => { this.form = node; }}
        onSubmit={this.handleSubmit}
        method="post"
      >
        <FormItem label={t('form.label.name')}>
          <Col md={5}>
            <Input
              placeholder={t('form.label.firstName')}
              validators={['required', 'isString']}
              errorMessages={[required, invalid]}
              name="firstName"
              onChange={this.handleChange}
              onBlur={this.handleFieldVisit}
              value={data.firstName}
              serverError={validation.firstName}
            />
          </Col>
          <Col md={2}>
            <Input
              placeholder={t('form.label.preposition.short')}
              // validators={['isString']}
              // errorMessages={[invalid]}
              name="preposition"
              onChange={this.handleChange}
              onBlur={this.handleFieldVisit}
              value={data.preposition}
            />
          </Col>
          <Col md={5}>
            <Input
              placeholder={t('form.label.lastName')}
              validators={['required', 'isString']}
              errorMessages={[required, invalid]}
              name="lastName"
              onChange={this.handleChange}
              onBlur={this.handleFieldVisit}
              value={data.lastName}
              serverError={validation.lastName}
            />
          </Col>
        </FormItem>
        <FormItem label={t('form.label.email')}>
          <Col md={12}>
            <Input
              placeholder={t('form.label.email')}
              validators={['required', 'isEmail']}
              errorMessages={[required, invalid]}
              name="email"
              onChange={this.handleChange}
              onBlur={this.handleFieldVisit}
              value={data.email}
              serverError={validation.email}
            />
          </Col>
        </FormItem>
        <FormItem label={t('form.label.phoneNumber')}>
          <Col md={12}>
            <Input
              placeholder={t('form.label.phoneNumber')}
              validators={['required', 'matchRegexp:^[0-9\\+]*$']}
              errorMessages={[required, invalid]}
              name="phoneNumber"
              onChange={this.handleChange}
              onBlur={this.handleFieldVisit}
              value={data.phoneNumber}
              serverError={validation.phoneNumber}
            />
          </Col>
        </FormItem>
        <FormItem label={t('form.label.birthdate.long')}>
          <Col md={4}>
            <Input
              placeholder="DD-MM-YYYY"
              validators={['required', 'isDate']}
              errorMessages={[required, invalid]}
              name="dateOfBirth"
              onChange={this.handleChange}
              onBlur={this.handleFieldVisit}
              value={data.dateOfBirth}
              mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              serverError={validation.dateOfBirth}
            />
          </Col>
        </FormItem>

        <FormItem label={t('form.label.previouslyActiveAsEmployee')}>
          <Col md={12}>
            <RadioGroup
              options={[
                {
                  label: t('form.label.yes'),
                  value: true,
                },
                {
                  label: t('form.label.no'),
                  value: false,
                },
              ]}
              onChange={this.handlePreviouslyActive}
              onBlur={this.handleFieldVisit}
              value={data.previouslyActiveAsEmployee}
              variant="horizontal"
              name="previouslyActiveAsEmployee"
            />
          </Col>
        </FormItem>
        {data.previouslyActiveAsEmployee === true
        && (
          <FormItem label={t('form.label.lastYearActiveAsEmployee')}>
            <Col md={2}>
              <Input
                placeholder="YYYY"
                validators={['required', 'isNumber']}
                errorMessages={[required, invalid]}
                name="lastYearActiveAsEmployee"
                onChange={this.handleChange}
                onBlur={this.handleFieldVisit}
                value={data.lastYearActiveAsEmployee}
                serverError={validation.lastYearActiveAsEmployee}
              />
            </Col>
          </FormItem>
        )}
        <div className="TeamHeading">
          <h3>{t('volunteer.teams')}</h3>
          <div className="BlockExplanation">
            {t('volunteer.which_team')}
          </div>
        </div>
        <FormItem
          label={`${t('form.label.preference.first')} ${t('form.label.preference.preference')}`}
        >
          <div className="PreferenceDropdown">
            <Dropdown
              options={workgroups}
              validators={['isSelected']}
              errorMessages={[required]}
              placeholder={`${t('form.label.preference.first')} ${t(
                'form.label.preference.preference',
              )}`}
              onChange={(event) => {
                this.handleWorkgroupSelectChange('firstPreferredWorkgroup', event);
              }}
              onBlur={this.handleFieldVisit}
              value={selection.firstPreferredWorkgroup}
              name="firstPreferredWorkgroup"
              isOptionDisabled={(option) => this.getDisabledStatus(1, option.value) === true}
            />
          </div>
        </FormItem>
        <FormItem
          label={`${t('form.label.preference.second')} ${t('form.label.preference.preference')}`}
        >
          <div className="PreferenceDropdown">
            <Dropdown
              options={workgroups}
              validators={['isSelected']}
              errorMessages={[required]}
              placeholder={`${t('form.label.preference.second')} ${t(
                'form.label.preference.preference',
              )}`}
              onChange={(event) => {
                this.handleWorkgroupSelectChange('secondPreferredWorkgroup', event);
              }}
              onBlur={this.handleFieldVisit}
              value={selection.secondPreferredWorkgroup}
              name="secondPreferredWorkgroup"
              isOptionDisabled={(option) => this.getDisabledStatus(2, option.value) === true}
            />
          </div>
        </FormItem>
        <FormItem
          label={`${t('form.label.preference.third')} ${t('form.label.preference.preference')}`}
        >
          <div className="PreferenceDropdown">
            <Dropdown
              options={workgroups}
              validators={['isSelected']}
              errorMessages={[required]}
              placeholder={`${t('form.label.preference.third')} ${t(
                'form.label.preference.preference',
              )}`}
              onChange={(event) => {
                this.handleWorkgroupSelectChange('thirdPreferredWorkgroup', event);
              }}
              onBlur={this.handleFieldVisit}
              value={selection.thirdPreferredWorkgroup}
              name="thirdPreferredWorkgroup"
              isOptionDisabled={(option) => this.getDisabledStatus(3, option.value) === true}
            />
          </div>
        </FormItem>
        <FormItem label={t('form.label.motivation')}>
          <Col md={12}>
            <Input
              placeholder={t('form.label.motivation')}
              validators={['required']}
              errorMessages={[required, invalid]}
              name="motivation"
              onChange={this.handleChange}
              onBlur={this.handleFieldVisit}
              value={data.motivation}
              serverError={validation.motivation}
              as="textarea"
              rows="5"
            />
          </Col>
        </FormItem>
        <div className="TeamFooter">
          <div className="BlockExplanation">
            {t('volunteer.disclaimer')}
          </div>
        </div>

        {formError !== '' && <Alert variant="danger">{formError}</Alert>}

        <Row className="Buttons">
          <Col md={6} />
          <Col md={6}>
            <div className="ButtonRight">
              <Button variant="primary" disabled={submitDisabled} type="submit">
                {t('button.submit')}
              </Button>
            </div>
          </Col>
        </Row>
      </ValidatorForm>
    );
  }
}

export default withTranslation()(VolunteerForm);

VolunteerForm.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  eventToken: PropTypes.string.isRequired,
};
