/* eslint-disable */
import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {localizeRoutePath} from 'components/LocalizedSwitch';
import LocalStorageService from 'services/storage/LocalStorageService';
import PropTypes from 'prop-types';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { t, i18n } = useTranslation('routes');

  return (
    <Route
      {...rest}
      render={(props) => (
        LocalStorageService.isLoggedIn()
          ? <Component {...props} />
          : (
            <Redirect
                to={`${localizeRoutePath('ticket.account_login', i18n, t)}?path=${props.location.pathname}`}
            />
          )
      )}
    />
  );
};

export default PrivateRoute;

PrivateRoute.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};