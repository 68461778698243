import React from 'react';
import {ReactComponent as Logo} from './logo.svg';
import {ReactComponent as ApplePay} from './payment/applepay.svg';
import {ReactComponent as Ideal} from './payment/ideal.svg';
import {ReactComponent as Bancontact} from './payment/bancontact.svg';
import {ReactComponent as Visa} from './payment/visa.svg';
import {ReactComponent as AmericanExpress} from './payment/amex.svg';
import {ReactComponent as MasterCard} from './payment/mastercard.svg';
import {ReactComponent as Mollie} from './payment/mollie.svg';
import {ReactComponent as Messenger} from './messenger.svg';

export default () => <Logo />;

export const LogoApplePay = () => <ApplePay />;
export const LogoIdeal = () => <Ideal />;
export const LogoBancontact = () => <Bancontact />;
export const LogoVisa = () => <Visa />;
export const LogoAmericanExpress = () => <AmericanExpress />;
export const LogoMasterCard = () => <MasterCard />;
export const LogoMollie = () => <Mollie />;
export const LogoMessenger = () => <Messenger />;
